
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'OptionDropDownRow',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  emits: ['handleClick'],
  setup(props, ctx) {
    const onItemClick = () => ctx.emit('handleClick');

    return {
      onItemClick,
    };
  },
});
