
import NewCard from '@/components/CardButton.vue';
import DocumentSvg from '@/assets/img/new-document.vue';
import {computed, defineComponent, onMounted, ref, watch} from 'vue';
import DocumentCard from '@/components/DocumentCard.vue';
import Pagination from '@/components/Pagination.vue';
import { IQuiz } from '@/types/quiz';
import { routeNewDocument } from '@/core/constants';
import {
  archiveQuizApi,
  getQuizFilters,
  GetQuizRequestFiltersPayload,
  searchQuizApi,
  getSidebarFilters,
  SidebarFilters,
} from '@/api/QuizApi';
import { useRouter, useRoute } from 'vue-router';
import QuizFilters from "@/components/QuizFilters.vue";
import {useStore} from 'vuex';
import {key} from '@/store';
import {echo} from '@/main';
import CheckedSvg from '@/assets/img/checked.vue';

export default defineComponent({
  name: 'DashboardPage',
  components: {
    CheckedSvg,
    QuizFilters,
    DocumentCard,
    NewCard,
    DocumentSvg,
    Pagination,
  },
  setup() {
    const store = useStore(key);
    const quizPayload = computed(() => store.state['getQuizPayload'])
    const refetchWithoutNameCounter = computed(() => store.state['refetchWithoutNameCounter'])
    const rNewDocument = ref(routeNewDocument);
    const documents = ref<IQuiz[]>([]);
    const router = useRouter();
    const route = useRoute()
    const pageMeta = ref({
      current_page: null,
      last_page: null,
      from: null,
      to: null,
      total: null,
    });
    const totalItems = ref<null | number>(null)

    // NEW FILTERS START
    const isFiltersOpen = ref(false);
    const sidebarFilters = ref<Array<SidebarFilters>>([]);



    const questionFilters = ref<Record<number,Array<string>>>({})
    const getQuestionsString = () => {
      let str = '';
      Object.keys(questionFilters.value).map(questionId => {
        console.log('2', questionId);
        sidebarFilters.value.forEach(question => {
          if(question.id === parseInt(questionId, 10)) {
            str += question.text + ': ';
            questionFilters.value[parseInt(questionId, 10)].forEach(selectedKey => {
              str += question.answer_values[selectedKey] + '. ';
            })
            str += '<br/>';
          }

        })

      })
      return str;
    }

      onMounted(() => { // aici se seteaza valorile din url
        if(typeof route.query.openedFilters === 'string') {
          console.log(route.query.openedFilters?.split('%2C'))
          openedFilters.value = route.query.openedFilters?.split('%2C').map(el => parseInt(el, 10))
        }

        if(typeof route.query.isFiltersOpen === 'string') {
          isFiltersOpen.value = route.query.isFiltersOpen  === 'true'
        }


        let payload : Record<number, Array<string>> = {};
        const data = { ...route.query};
        delete data.openedFilters;
        delete data.isFiltersOpen;

        Object.keys(data).map(el => {
          if(el.includes('_$_')) {
            const index = el.indexOf('_$_') + 3;
            const questionId = parseInt(el.substring(index), 10);
            if(payload.hasOwnProperty(questionId)) {
              console.log('asd',data[el]);
              payload[questionId].push(decodeURIComponent(data[el] as string))
            } else {
              console.log('asd',data[el]);
              payload[questionId] = [decodeURIComponent(data[el] as string)]
            }
          } else {
            const questionId = parseInt(el, 10);
            console.log('3asd',data[el]);
            payload[questionId] = [decodeURIComponent(data[el] as string)]
          }
        });

        questionFilters.value = payload;
        store.commit('setQuizPayload', {
          ...quizPayload.value,
          filter: {
            ...quizPayload.value.filter,
            question_ids: payload
          }
        });

        initialFetchQuiz();
      })

    const openedFilters = ref<Array<number>>([]);

    const toggleOpenFilters = (id: number) => {
      const findEl = openedFilters.value.indexOf(id);
      if(findEl === -1) {
        openedFilters.value.push(id);
      } else {
        openedFilters.value.splice(findEl, 1)
      }
      setUrl();
    }

    const isOpenFilter = (id: number) => {
      return openedFilters.value.indexOf(id) !== -1;
    }

    const openFilter = () => {
      isFiltersOpen.value =  !isFiltersOpen.value;
      setUrl();
    }

    const setUrl = () => { // set filters data in URL
      const payload: Record<string, string> = {}
      Object.keys(questionFilters.value).map((keyRef) => {
        const key = parseInt(keyRef, 10);
        const questionIdHaveMoreThenOneValue = questionFilters.value[key].length > 1;
        if(questionIdHaveMoreThenOneValue) {
          questionFilters.value[key].forEach((el, index) => {
            payload[`${index}_$_${key}`] = encodeURIComponent(el);
          })
        } else  {
          payload[key] = encodeURIComponent(questionFilters.value[key][0]);
        }
      })


      router.replace({query: {
          isFiltersOpen: encodeURIComponent(isFiltersOpen.value),
          openedFilters: encodeURIComponent(openedFilters.value.join(',')),
          ...payload
        }})
    }

    const toggleQuestionForFiltering = (questionId: number, questionValue: string) => {

      const itemAlreadyExist = questionFilters.value.hasOwnProperty(questionId);
      if(itemAlreadyExist) {

        const isValueAlreadySelected = questionFilters.value[questionId].includes(questionValue)
        if(isValueAlreadySelected) {
          questionFilters.value[questionId] = questionFilters.value[questionId].filter(el => el !== questionValue);

          const shouldKeyBeRemoved = questionFilters.value[questionId].length === 0
          if(shouldKeyBeRemoved) {
            delete questionFilters.value[questionId];
          }
        } else {
          questionFilters.value[questionId] = [...questionFilters.value[questionId], questionValue]
        }

      } else {
        questionFilters.value[questionId] = [questionValue];
      }
      setUrl();

      store.commit('setQuizPayload', {
        ...quizPayload.value,
        filter: {
          ...quizPayload.value.filter,
          question_ids: questionFilters.value
        }
      });
      initialFetchQuiz();
    }

    const isQuestionItemSelected =(questionId: number, questionValue: string) => {
      const itemAlreadyExist = questionFilters.value.hasOwnProperty(questionId);
      if(itemAlreadyExist) {
        return questionFilters.value[questionId].includes(questionValue)
      }
      return false;
    }
    onMounted(() => {
      getSidebarFilters().then(res => {
        sidebarFilters.value = res;
      });
    })

    const clearFilter = () => {
      questionFilters.value = [];
      setUrl();

      store.commit('setQuizPayload', {
        ...quizPayload.value,
        filter: {
          ...quizPayload.value.filter,
          question_ids: []
        }
      });
      initialFetchQuiz();
    }

    const questionHaveSelectedValue = (filter: SidebarFilters) => {
      return questionFilters.value.hasOwnProperty(filter.id);
    }

    const haveQuestionSelected = () => {
      return Object.keys(questionFilters.value).length > 0;
    }
    // NEW FILTERS END

    const progressMessage = ref('');
    const businessFunctionId = ref(-1);
    onMounted(() => {
      getQuizFilters().then(res => {
        businessFunctionId.value = res['business_function'].question_template_id;
      });
    })

    onMounted(() => {
      echo?.channel('quiz')
        .listen('.quiz.update_progress', ({data : {done, total}}: {data: {done: number, total: number}}) => {
          console.log(`Completed ${done} from ${total}`);
          let message = '';
          if(done !== total) {
            message = `Procession quizes ${done} from ${total}`
          };
          progressMessage.value = message;
          // console.log(e.data);
          // console.log(e.data.done);
          // console.log(e.data.total);
        })
    })

    onMounted(() => {
      echo?.channel('quiz')
        .listen('.quiz.updated', (e: any) => {
          const new_document: IQuiz[] = [];
          documents.value.forEach(quiz => {
            if(quiz.id === e.data.id) {
              quiz.is_update_pending = false;
            }
            new_document.push(quiz)
          })
          documents.value = new_document;
        })
    })

    const handleQuizResponse = (res: any) => {
      documents.value = res.data.data;
      pageMeta.value = res.data.meta;
    };

    const getQuizzes = (page = 1) => {
      const payload = {
        ...quizPayload.value,
        page,
      }
      store.commit('setQuizPayload', payload);
      searchQuizApi(payload).then(handleQuizResponse);
    };

    const initialFetchQuiz = () => {
      searchQuizApi(quizPayload.value).then(handleQuizResponse);
      searchQuizApi({
        page: 1,
        name: '',
        sort_by: "created_at",
        sort_dir: "desc"
      }).then(res => {
        console.log(res.data.meta);
        debugger;
        totalItems.value = res.data.meta.total;
      });
    }

    const goToQuizEdit = (quizId: number) => {
      router.push({
        name: 'question',
        params: {
          id: quizId,
        },
      });
    };

    watch(refetchWithoutNameCounter, () => {
      handleSearch('');
    });

    const handleSearch = (searchInputValue: string): void => {
      const payload = {
        ...quizPayload.value,
        name: searchInputValue,
        page: 1,
      }
      store.commit('setQuizPayload', payload) // removed on request 21.08.2023 Ser D

        searchQuizApi(payload).then(handleQuizResponse);
    };
    const navigateToPage = (page: number) => {
      getQuizzes(page);
    };

    const archiveQuiz = (quizId: number) => {
      archiveQuizApi(quizId).then(() => {
        getQuizzes(pageMeta.value.current_page || 1);
      });
    };

    function onFilterChange(data: GetQuizRequestFiltersPayload) {
      const payload = {
        ...quizPayload.value,
        ...data,
        page: 1,
      }
      // todo
      store.commit('setQuizPayload', payload);
      searchQuizApi(payload).then(handleQuizResponse);
    }

    return {
      documents,
      rNewDocument,
      goToQuizEdit,
      handleSearch,
      navigateToPage,
      archiveQuiz,
      pageMeta,
      onFilterChange,
      businessFunctionId,
      progressMessage,
      openFilter,
      isFiltersOpen,
      sidebarFilters,
      isOpenFilter,
      toggleOpenFilters,
      toggleQuestionForFiltering,
      questionFilters,
      isQuestionItemSelected,
      quizPayload,
      totalItems,
      questionHaveSelectedValue,
      clearFilter,
      haveQuestionSelected,
      getQuestionsString,
    };
  },
  created() {
    // axiosInstance.defaults.headers.common.Authorization = `Bearer ${this.$auth.getAccessToken()}`;
  },
});
