

import {defineComponent, onMounted, ref} from "vue";
import {apiGetReport} from "@/api/reportApi";
import ReportQuadrant from "@/components/ReportQuadrant.vue";
import SelectInput from "@/components/SelectInput.vue";
import DropDown from "@/components/DropDown.vue";

export default defineComponent( {
  name: 'MaintenancePage',
  components: {},
  setup: () => {
    // return {
    // }
  }
});
