import req from "@/api/utilities/axios";

export interface Category {
  id: number,
  name: string,
  created_at: string;
  updated_at: string;
}

export const apiGetCategories = (): Promise<Category[]> => req.get(`category`).then(res => res.data.data)
export const apiEditCategory = ({ id, name }: {id: number, name: string}):
  Promise<Category> => req.put(`category/${id}`, {name}).then(res => res.data.data)
