
import {defineComponent, PropType, ref} from 'vue';
import { onClickOutside } from '@vueuse/core';
import OptionSvg from '@/assets/img/option.vue';
import OptionOpenSvg from '@/assets/img/option-open.vue';

export default defineComponent({
  name: 'OptionDropDown',
  components: {
    OptionSvg,
    OptionOpenSvg,
  },
  props: {
    expandOn: {
      type: String as PropType<'left' | 'right'>,
      default: 'left',
    },
  },
  setup() {
    const isDropDownOpen = ref(false);
    const optionDropDownWrapper = ref(null);
    const isHovered = ref(false);

    const closeDropDownFn = () => {
      isDropDownOpen.value = false;
    };
    const openDropDownFn = () => {
      isDropDownOpen.value = true;
    };
    onClickOutside(optionDropDownWrapper, (event) => {
      closeDropDownFn();
    });

    const onClickInsideSlot = () => {
      closeDropDownFn();
    };

    const onMouseOver = () => {
      isHovered.value = true;
    }
    const onMouseOut = () => {
      isHovered.value = false;
    }

    return {
      isDropDownOpen,
      openDropDownFn,
      closeDropDownFn,
      onClickOutside,
      optionDropDownWrapper,
      onClickInsideSlot,
      isHovered,
      onMouseOver,
      onMouseOut
    };
  },
});
