import { IQuestion } from '@/types/question';
import { ulid } from 'ulid';
export const NEW_QUESTION_ID = -1;
export function createTextQuestion(position: number, category_id: number): IQuestion {
  const quizTextModel: IQuestion = {
    id: NEW_QUESTION_ID,
    text: 'Text',
    position,
    type: 'text',
    user_answer: '',
    rules: [],
    answer_values: {
      [ulid()]: '',
    },
    created_at: new Date(),
    updated_at: new Date(),
    is_required: false,
    category_id: category_id,
    isNewQuestion: true,
  };
  return quizTextModel;
}
export default createTextQuestion;
