import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-067fa22d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "log-in-page-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_button, { onHandleClick: _ctx.logIn }, {
      default: _withCtx(() => [
        _createTextVNode("Log in")
      ]),
      _: 1
    }, 8, ["onHandleClick"])
  ]))
}