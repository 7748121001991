import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withKeys as _withKeys, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "optionDropDownWrapper",
  class: "option-drop-down-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_option_open_svg = _resolveComponent("option-open-svg")!
  const _component_option_svg = _resolveComponent("option-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "option-drop-down-open-wrapper",
      style: _normalizeStyle({
           backgroundColor: _ctx.isDropDownOpen || _ctx.isHovered ? '#012B74' : 'transparent'
         }),
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openDropDownFn && _ctx.openDropDownFn(...args)), ["stop"])),
      onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.openDropDownFn && _ctx.openDropDownFn(...args)), ["stop"]), ["enter"])),
      onMouseover: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onMouseOver && _ctx.onMouseOver(...args))),
      onMouseout: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onMouseOut && _ctx.onMouseOut(...args)))
    }, [
      (_ctx.isDropDownOpen || _ctx.isHovered)
        ? (_openBlock(), _createBlock(_component_option_open_svg, {
            key: 0,
            class: "option-drop-down-open-svg"
          }))
        : (_openBlock(), _createBlock(_component_option_svg, {
            key: 1,
            class: "option-drop-down-open-svg"
          }))
    ], 36),
    (_ctx.isDropDownOpen)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "option-drop-down-list",
          style: _normalizeStyle({
        right: _ctx.expandOn === 'left' ? 0 : 'auto',
        left: _ctx.expandOn === 'right' ? 0 : 'auto',
      }),
          onClick: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClickInsideSlot && _ctx.onClickInsideSlot(...args)), ["stop"])),
          onKeydown: _cache[5] || (_cache[5] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.onClickInsideSlot && _ctx.onClickInsideSlot(...args)), ["stop"]), ["enter"]))
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 36))
      : _createCommentVNode("", true)
  ], 512))
}