
import {defineComponent} from "vue";
import {QuadrantItem} from "@/api/reportApi";
import DoneSvg from "@/assets/img/doneSvg.vue";

export default defineComponent({
  name: 'ReportQuadrant',
  components: {DoneSvg},
  props: {
    title: {
      type: String,
    },
    periodFrom: {
      type: String,
    },
    periodTo: {
      type: String,
    },
    quadrant: {
      type: Object as () => QuadrantItem,
      required: true,
    },
  },
  // setup(props) {
  //
  // }
})
