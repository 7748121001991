
import UploadTypeDropDown from '@/components/UploadTypeDropDown.vue'
import TheGradient from '@/components/TheGradient.vue';
import UploadBox from '@/components/UploadBox.vue';
import {computed, defineComponent, onBeforeUnmount, onMounted, ref} from "vue";
import {
  apiCancelDownloadPartnerData,
  apiDownloadPartnerData,
  apiGetImportStatus,
  apiImport,
  apiImportAndAdd,
  apiImportAndReplace,
  ImportResource
} from '@/api/importApi';
import useModal from '@/hooks/useModal';
import TheModal from '@/components/TheModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import TitleText from '@/components/TitleText.vue';
import GrayText from '@/components/GrayText.vue';
import UploadPageProgress from '@/components/UploadPageProgress.vue';
import ProgressBar from "@/components/ProgressBar.vue";
import {AxiosError} from 'axios';
import {echo} from '@/main';
import {ulid} from 'ulid';

import {getLoggedInUser} from '@/api/userApi';
import {User} from '@/User';
import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue';
import {useRouter} from 'vue-router';
import {routeBulkDownloadSelectedPartners} from '@/core/constants';
export default defineComponent({
  name: 'UploadPage',



  components: {
    ConfirmDeleteModal,
    UploadTypeDropDown,
    TheGradient,
    UploadBox,
    TheModal,
    BaseButton,
    TitleText,
    GrayText,
    UploadPageProgress,
    ProgressBar,
  },
  setup() {
    let timer: number;
    const router = useRouter();
    const uploadType = ref(1);
    const importFileId = ref<number | null>(null);

    const uploadCompletedModal = useModal(false);
    const errorModal = useModal(false);
    const errorMessage = ref('');

    const confirmDeleteFilesModal = useModal(false);

    const deleteValue = ref<string>('');

    const isAllPartnerDataDownloading = ref(false);


    const downloadProgress = ref<number>(0);

    const download_id = ref<null | string>(null);

    const fileStatus = ref<null | ImportResource>(null);

    let downloadAbortController = new AbortController();

    const user = ref<User | undefined >();

    const confirmDeleteDataAndImportNewModal = useModal(false);
    const confirmDeleteDataAndImportNewModalSecondModal = useModal(false);

    const havePermissionToDownload = computed(() => {
      return !!(user.value && user.value.permissions.export)
    });

    const percentProgress = computed(() => {
      if (
        fileStatus.value !== null
        && fileStatus.value.processed_lines
        &&  fileStatus.value.total_lines
      ) { // not best option
        return ( fileStatus.value.processed_lines / fileStatus.value.total_lines) * 100
      }
      return 0;
    })
    const uploadLabel = computed(() => {
      switch(uploadType.value) {
        case 1: return 'Import additional data';
        case 2: return 'Delete all data / import new data';
        case 3: return 'Download all partner data';
        case 4: return 'Download selected partners data';
        default : return 'Import additional data';
      }
    });

    type QuizProgressEventType ={data: {current: number, total: number, request_id: string}};

    onMounted(() => {
      echo?.channel('quiz')
        .listen('.quiz.export_progress', (
          {
            data : {
              current,
              total,
              request_id
            }
          } : QuizProgressEventType
        ) => {
          if(request_id === download_id.value) {
            const onePercent = total / 100;
            const progress = current / onePercent;
            downloadProgress.value = progress / 100;
          }
        })
    })

    onMounted(() => {
      // //@todo
      // user.value = {
      //   name: 'demo',
      //   email: 'asd',
      //   created_at: '123123',
      //   id: 1,
      //   permissions: {
      //     export: true,
      //   },
      //   updated_at: '123123'
      // }
      getLoggedInUser()
        .then(data => {
          user.value = data;
        })
        .catch(e => {
          console.log('e', e);
        })
    })

    const uploadTypeIsChanged = (newUploadType: number) => {
      const isDeleteAllPartnerDataAndImportNewData = 2;
      const isDownloadSelectedPartners = 4
      if(newUploadType === isDeleteAllPartnerDataAndImportNewData) {
        confirmDeleteDataAndImportNewModal.open();
        return;
      }

      if(newUploadType === isDownloadSelectedPartners) {
        router.push({
          name: routeBulkDownloadSelectedPartners,
        });
        return;
      }
      uploadType.value = newUploadType;
    };


    const downloadAllPartnerData = () => {
      isAllPartnerDataDownloading.value = true;

      download_id.value = ulid();

      apiDownloadPartnerData(download_id.value, downloadAbortController.signal)
        .then(response => {
          const blob = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = blob;
          link.setAttribute('download', response.headers?.filename);
          document.body.appendChild(link);
          link.click();
        })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        isAllPartnerDataDownloading.value = false;
        downloadProgress.value = 0;
      });
    }

    const abortDownload = () => {
      downloadAbortController.abort();
      isAllPartnerDataDownloading.value = false;
      downloadProgress.value = 0;

      if(download_id.value) {
        apiCancelDownloadPartnerData(download_id.value)
          .then(() => {
            download_id.value = null;
          })
      }

      downloadAbortController = new AbortController()
    }

    onBeforeUnmount(() => {
      abortDownload();
    })
    const launchImportProcess = (id: number) => {
      if(uploadType.value === 1) {
        apiImportAndAdd(id)
          .then(() => {
            updateFileStatus(id);
          })
          .catch(handleResponseError);
      } else if( uploadType.value === 2) {
        confirmDeleteFilesModal.open();
      }
    }

    const importAndReplace = () => {
      const id = importFileId.value;
      if(!id || confirmDeleteFilesConfirmButtonDisabled.value) {
        return;
      }
      if(id) {
        apiImportAndReplace(id)
          .then(() => {
            updateFileStatus(id);
            handleConfirmDeleteFilesModalClose();
          })
          .catch(handleResponseError);
      }
    }

    const confirmDeleteFilesConfirmButtonDisabled = computed(
      () => deleteValue.value.toLowerCase() !== 'delete'
    );

    const handleConfirmDeleteFilesModalClose = () => {
      confirmDeleteFilesModal.close();
      deleteValue.value = '';
    }


    const updateFileStatus = (id: number) => {

      timer = setInterval(() => {
        apiGetImportStatus(id)
          .then(res => {
            fileStatus.value = res.data.data;

            if(res.data.data.state === 'done') {
              clearInterval(timer);
              fileStatus.value = null;
              uploadCompletedModal.open();
            }

            // console.log(res.data.data);
          }).catch((e) => {
          handleResponseError(e);
          fileStatus.value = null;
        } );
      }, 3000)

    }

    const onFileChosen = (file: Blob) => {
      const payload = new FormData();
      payload.append('csv', file);
      apiImport(payload)
        .then(res => {
          importFileId.value = res.data.data.id;
          launchImportProcess(res.data.data.id)
        })
        .catch(handleResponseError)

    }

    const handleResponseError = (e: AxiosError) => {
      errorMessage.value = e?.response?.data?.message || 'Something went wrong!';
      errorModal.open();
    };

    onBeforeUnmount(() => {
      clearInterval(timer)
    })

    return {
      uploadType,
      uploadTypeIsChanged,
      uploadLabel,
      onFileChosen,
      fileStatus,
      uploadCompletedModal,
      confirmDeleteFilesModal,
      errorModal,
      errorMessage,
      deleteValue,
      confirmDeleteFilesConfirmButtonDisabled,
      importAndReplace,
      handleConfirmDeleteFilesModalClose,
      percentProgress,
      isAllPartnerDataDownloading,
      downloadProgress,
      abortDownload,
      havePermissionToDownload,
      confirmDeleteDataAndImportNewModal,
      confirmDeleteDataAndImportNewModalSecondModal,
      downloadAllPartnerData,
    }
  }
});
