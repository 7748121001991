
import { defineComponent, PropType, ref } from 'vue';
import { IQuestion } from '@/types/question';
import BaseButton from '@/components/BaseButton.vue';
import SelectInput from '@/components/SelectInput.vue';
import CloseSvg from '@/assets/img/close.vue';
import RuleMultipleSelect from '@/components/RuleMultipleSelect.vue';
import GrayText from '@/components/GrayText.vue';
export default defineComponent({
  name: 'QuestionRules',
  components: {
    BaseButton,
    CloseSvg,
    RuleMultipleSelect,
    GrayText
  },
  props: {
    ix: {
      type: Number,
      required: true,
    },
    allQuestions: {
      type: Object as PropType<IQuestion[]> | null,
      required: true,
    },
  },
  emits: ['cancelRules', 'saveRules'],
  setup(props, ctx) {
    const initialQuestion = JSON.parse(JSON.stringify(props.allQuestions[props.ix]));
    const allQuestions: IQuestion[] = JSON.parse(JSON.stringify(props.allQuestions));
    const currentQuestion = ref<IQuestion>(allQuestions[props.ix]);

    const getQuestionIndexById = (questionId: number): number => {
      let questionIndex = -1;
      allQuestions.forEach((qestion, ix) => {
        if (qestion.id === questionId) {
          questionIndex = ix;
        }
      });
      return questionIndex;
    };

    const handleRuleSave = () => {
      ctx.emit('saveRules', currentQuestion.value.rules);
    };

    const handleRuleCancel = () => {
      currentQuestion.value = initialQuestion;
      ctx.emit('cancelRules');
    };

    const selectRuleAvailableOptions = (haveValue: null | number[]) => {
      const res = [];

      if(haveValue) { // add options for questions under rule which are upper then current
        for (let i = 0; i < props.ix; i += 1) {
          if(haveValue.includes(allQuestions[i].id)) {
            res.push({
              label: allQuestions[i].text,
              value: allQuestions[i].id,
            });
          }
        }
      }

      for (let i = props.ix + 1; i < allQuestions.length; i += 1) {
        res.push({
          label: allQuestions[i].text,
          value: allQuestions[i].id,
        });
      }
      return res;
    };

    const getRuleValueByAnswerValue = (answerValueId: string) => {
      let ret = null;
      currentQuestion.value.rules.forEach((el) => {
        if (el.answer_value_key === answerValueId) {
          ret = el.question_ids;
        }
      });
      return ret;
    };

    const removeRule = (answerValueId: string) => {
      let elIx = null;
      currentQuestion.value.rules.forEach((el, ix) => {
        if (el.answer_value_key === answerValueId) {
          debugger;
          elIx = ix;
        }
      });
      if(elIx !== null) {
        currentQuestion.value.rules.splice(elIx, 1);
      }

    };

    const onRuleSelected = (data: {answer_value_key: string, question_id: number}) => {
      let ruleIx = -1;
      currentQuestion.value.rules.forEach((el, ix) => {
        if (el.answer_value_key === data.answer_value_key) {
          ruleIx = ix;
        }
      });

        if (ruleIx === -1) {
          currentQuestion.value.rules.push({
            answer_value_key: data.answer_value_key,
            question_ids: [data.question_id]
          });
        } else {
          const questionIndex = currentQuestion.value.rules[ruleIx].question_ids.indexOf(data.question_id);
          const questionIdNotInArray = questionIndex === -1;
          if(questionIdNotInArray) {
            currentQuestion.value.rules[ruleIx].question_ids = [
              ...currentQuestion.value.rules[ruleIx].question_ids,
              data.question_id,
            ]
          } else {
            currentQuestion.value.rules[ruleIx].question_ids.splice(questionIndex, 1)
            const ruleHaventQuestionIds = currentQuestion.value.rules[ruleIx].question_ids.length === 0
            if(ruleHaventQuestionIds) {
              currentQuestion.value.rules.splice(ruleIx, 1)
            }
          }

        }
    }

    return {
      handleRuleSave,
      handleRuleCancel,
      currentQuestion,
      selectRuleAvailableOptions,
      getRuleValueByAnswerValue,
      removeRule,
      onRuleSelected,
    };
  },
});
