
import CienaLayout from '@/components/CienaLayout.vue';
import BackSvg from '@/assets/img/arrow-back.vue';
import BaseButton from '@/components/BaseButton.vue';
import PlusSvg from '@/assets/img/plus.vue';
import DocumentSvg from '@/assets/img/new-document.vue';
import TemplateSvg from '@/assets/img/template.vue';
import NewCard from '@/components/CardButton.vue';
import { computed, defineComponent, ref, watch } from 'vue';
// import GradientPicker from '@/components/GradientPicker.vue';
import { useRouter } from 'vue-router';
import apiQuiz, { createQuiz } from '@/api/QuizApi';

export default defineComponent({
  name: 'NewDocumentPage',
  components: {
    // GradientPicker,
    BaseButton,
    CienaLayout,
    NewCard,
    BackSvg,
    PlusSvg,
    DocumentSvg,
    TemplateSvg,
  },
  setup() {
    const name = ref('');
    const colorId = ref(Math.floor(Math.random() * (3 - 1 + 1) + 1));
    const createStep = ref(1);
    const router = useRouter();

    const createDocumentError = ref('');

    const isNameValidForSubmit = computed(() => name.value.length >= 1);
    const isOnFirstStep = computed(() => createStep.value === 1);
    const isOnSecondStep = computed(() => createStep.value === 2);

    const createDocumentWithStandardQuestion = () => {
      createQuiz({
        name: name.value,
        color: colorId.value,
        use_template: true,
      })
        .then((res) => {
          router.push({
            name: 'question',
            params: {
              id: res.data.data.id,
            },
          });
        })
        .catch((err) => {
          const message = Object.keys(err.response.data.errors).map(
            (key) => err.response.data.errors[key][0]
          );
          createDocumentError.value = `${err.response.data.message} ${message}`;
        });
    };

    watch(name, () => {
      createDocumentError.value = '';
    });

    const toSecondStep = () => {
      if (isNameValidForSubmit.value) {
        createDocumentWithStandardQuestion();
        // createStep.value = 2;
      } else {
        throw new Error('Name is not valid');
      }
    };

    const createDocument = () => {
      apiQuiz
        .post({
          name: name.value,
          color: colorId.value,
          use_template: false,
        })
        .then(({ id }) => {
          router.push({
            name: 'edit-question',
            params: {
              id,
            },
          });
        });
    };

    const selectProjectColor = (color: number) => {
      colorId.value = color;
    };

    return {
      isNameValidForSubmit,
      name,
      isOnFirstStep,
      isOnSecondStep,
      toSecondStep,
      createDocument,
      createDocumentWithStandardQuestion,
      selectProjectColor,
      createDocumentError,
    };
  },
});
