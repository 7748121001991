import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7dc8bcb5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "upload-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_upload_svg = _resolveComponent("upload-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_upload_svg, { class: "mb-24" }),
    _createElementVNode("div", null, [
      _createTextVNode(_toDisplayString(_ctx.text) + " ", 1),
      _createElementVNode("span", {
        class: "choose-file",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.chooseFile && _ctx.chooseFile(...args)))
      }, "choose file")
    ])
  ]))
}