
import CienaLogo from '@/assets/img/ciena-logo.vue';
import Hamburger from '@/assets/img/hamburger.vue';
import SearchInputHeader from '@/components/SearchInputHeader.vue';
import {computed, defineComponent} from 'vue';
import {useStore} from 'vuex';
import {key} from '@/store';

export default defineComponent({
  name: 'TheHeader',
  components: {
    CienaLogo,
    SearchInputHeader,
    Hamburger,
  },
  props: {
    isOpened: {
      type: Boolean,
      required: true,
    },
    noSearch: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['onSearchBarSubmit', 'onOpenSidebar'],
  setup () {
    const store = useStore(key);
    const inputValue = computed(() => store.state['getQuizPayload'].name)

    return {
      inputValue,
    }
  },
  methods: {
    async login() {
      // await this.$auth.signInWithRedirect();
    },
  },
});
