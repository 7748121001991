import { BASE_URL } from '@/constants/app-config';
import axios from 'axios';
import store from "@/store";
import Router from '@/router/index';
import {routeLogIn, routeMaintenance} from "@/core/constants";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

axiosInstance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error

  if(error?.response?.status === 503) {
    Router.push({name: routeMaintenance});
  }
  if(error?.response?.status === 401) {
    localStorage.removeItem('token');
    store.commit('setIsAuthenticated', false);
    Router.push({name: routeLogIn});
  }
  return Promise.reject(error);
});
export default axiosInstance;

