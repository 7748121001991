import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_VueDatePicker, {
      modelValue: _ctx.date,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event)),
      vertical: "",
      format: "MM-dd-yy",
      onDateUpdate: _ctx.onFilterByDates,
      onCleared: _ctx.cleared
    }, null, 8, ["modelValue", "onDateUpdate", "onCleared"])
  ]))
}