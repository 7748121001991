

import {computed, defineComponent, ref} from 'vue';
import TitleText from '@/components/TitleText.vue';
import GrayText from '@/components/GrayText.vue';
import BaseButton from '@/components/BaseButton.vue';
import TheModal from '@/components/TheModal.vue';

export default defineComponent({
  name: 'ConfirmDeleteModal',
  components: {
    TheModal,
    BaseButton,
    GrayText,
    TitleText,
  },
  props:{
    isOpen: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: '',
    },
    confirmButtonText: {
      type: String,
      default: 'Delete',
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel',
    },
    toMatchValue: {
      type: String,
      default: 'delete',
    }
  },
  emits: ['onConfirm', 'onCancel'],
  setup(props, ctx) {
    const deleteValue = ref<string>('');
    const confirmDeleteButtonDisabled = computed(
      () => deleteValue.value.toLowerCase() !== props.toMatchValue
    );

    const resetInput = () => {
      deleteValue.value = '';
    }

    const handleCancelClick = () => {
      resetInput();
      ctx.emit('onCancel');
    }

    const handleConfirmClick = () => {
      resetInput();
      ctx.emit('onConfirm');
    }
    return {
      deleteValue,
      confirmDeleteButtonDisabled,
      handleCancelClick,
      handleConfirmClick,
    }
  }
})
