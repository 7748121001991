import { AxiosError, AxiosResponse } from 'axios';
import apiProvider from '@/api/utilities/provider';
import { IApiCore, IApiCoreOptions } from '@/api/utilities/interfaces';

export default class ApiCore implements IApiCore {
  getSingle!: ((id: number) => Promise<AxiosResponse<any> | AxiosError<any>>);

  getAll!: (() => Promise<any>);

  post!: ((model: any) => Promise<any>);

  update!: ((id: number | string, model: any) => Promise<any>);

  patch!: ((model: any) => Promise<any>);

  remove!: ((id: any) => Promise<any>);

  constructor(options: IApiCoreOptions) {
    if (options.getAll) {
      this.getAll = () => apiProvider.getAll(options.url);
    }

    if (options.getSingle) {
      this.getSingle = (id) => apiProvider.getSingle(options.url, id);
    }

    if (options.post) {
      this.post = (model) => apiProvider.post(options.url, model);
    }

    if (options.update) {
      this.update = (id, model) => apiProvider.update(options.url, id, model);
    }

    if (options.patch) {
      this.patch = (model) => apiProvider.patch(options.url, model);
    }

    if (options.delete) {
      this.remove = (id) => apiProvider.remove(options.url, id);
    }
  }
}
