

import {computed, defineComponent, onMounted, ref} from "vue";
import {apiEditCategory, apiGetCategories, Category} from "@/api/categoryApi";
import {useStore} from "vuex";
import {key} from "@/store";
import OptionDropDownRow from "@/components/OptionDropDown/OptionDropDownRow.vue";
import OptionDropDown from "@/components/OptionDropDown/OptionDropDown.vue";
import CategoryEditCancel from '@/assets/img/CategoryEditCancel.vue';
import CategoryEditDone from '@/assets/img/CategoryEditDone.vue';
import ConfirmModal from "@/components/ConfirmModal.vue";
import {ACTIVITY_ID, ATTACHMENTS_ID} from '@/core/constants';


export default defineComponent({
  name: 'QuestionCategory',
  components: {
    OptionDropDown,
    OptionDropDownRow,
    CategoryEditCancel,
    CategoryEditDone,
    ConfirmModal,
  },
  setup() {
    const store = useStore(key);
    const newQuestionPositionIndex = computed(() => store.state.createQuestion.questionPositionIndex)
    const editedCategoryName = ref<{
      id: number,
      name: string,
    }>({
      id: -1,
      name: ''
    });
    const isConfirmEditVisible = ref(false);
    onMounted(async () => {
      try {
        const categories = await apiGetCategories();
        updateCategoriesInStore(categories);
        if(newQuestionPositionIndex.value === null) { // wouldn't choose default category in case it's going back from create question page
          setInitialCurrentCategory(categories);
        }
      } catch (e) {
        throw new Error('Failing Getting categories');
      }
    })

    const updateCategoriesInStore = (categories: Category[]) => {
      store.commit('setCategories', categories);
    }

    const setInitialCurrentCategory = (categories: Category[]) => {
      store.commit('setCurrentCategory', categories[0].id);
    }

    const categories = computed((): Category[] => store.state.categories)
    const currentCategory = computed(() => store.state.currentCategory);
    const isEditingCategory = computed(() => store.state.isEditingCategory);

    const openCategory = (categoryId: number | null) => {
       if(isEditingCategory.value) {
         return ;
       }
      window.scrollTo(0,0);
      store.commit('setCurrentCategory', categoryId);
    }

    const onEditMenu = (category: Category) => {
      editedCategoryName.value = {
        id: category.id,
        name: category.name,
      };
      store.commit('setIsEditingCategory', true);
    }

    const cancelEditing = () => {
      editedCategoryName.value = {
        id: -1,
        name: 'category.name',
      };
      store.commit('setIsEditingCategory', false);
    }

    const handleEditing = async (category: Category) => {
      isConfirmEditVisible.value = true;
    }
    const cancelEditingConfirmModal = () => {
      isConfirmEditVisible.value = false;
      cancelEditing()
    }
    const confirmCategoryEditModal = async () => {
      try {
        await apiEditCategory({
          id: editedCategoryName.value.id,
          name: editedCategoryName.value.name,
        });
        const categories = await apiGetCategories();
        updateCategoriesInStore(categories);
      } catch (e) {
        throw new Error('Edit category fail')
      } finally {
        cancelEditing();
        isConfirmEditVisible.value = false;
      }
    }

    const openActivity = () => {
      openCategory(ACTIVITY_ID);
    }

    const openAttachments = () => {
      openCategory(ATTACHMENTS_ID);
    }

    return {
      categories,
      currentCategory,
      isEditingCategory,
      openCategory,
      onEditMenu,
      cancelEditing,
      handleEditing,
      editedCategoryName,
      isConfirmEditVisible,
      cancelEditingConfirmModal,
      confirmCategoryEditModal,
      store,
      openActivity,
      ACTIVITY_ID,
      openAttachments,
      ATTACHMENTS_ID,
    }
  }
})
