<template>
  <div
    :class="{'radio-button': true, 'radio-button-active': isActive}"
    @click="$emit('handleValueChange', value)"
    @keydown.enter="$emit('handleValueChange', value)"
    >{{label}}</div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'RadioButton',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['handleValueChange'],
});
</script>
<style lang="sass">
@import '../assets/sass/base/colors'
.radio-button
  padding: 16px 34px
  font-family: Inter, sans-serif;
  font-size: 14px;
  color: $purple;
  background: $grey-5
  border: 1px solid $grey-4
  border-radius: 8px
  transition: .3s
  &.radio-button-active, &:hover
    background-color: $white
    border: 1px solid $purple
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.08);
</style>
