
import {defineComponent, onMounted, ref} from 'vue';
import {apiGetQuiz, apiGetQuizActivityLogs, getUserList, LogItem} from '@/api/QuizApi';
import Pagination, {metaInterface} from '@/components/Pagination.vue';
import {formatInTimeZone} from 'date-fns-tz';
import TheGradient from '@/components/TheGradient.vue';
import GrayText from '@/components/GrayText.vue';
import DatePicker, {CalendarDialog} from 'vue-time-date-range-picker/dist/vdprDatePicker';
import 'vue-time-date-range-picker/dist/vdprDatePicker.min.css'
import SelectInput from '@/components/SelectInput.vue';
import question from '@/components/Question/Question.vue';
import {el} from 'date-fns/locale';

export default defineComponent({
  name: 'QuestionActivity',
  computed: {
    question() {
      return question
    }
  },
  components: {
    TheGradient,
    Pagination,
    GrayText,
    // CalendarDialog,
    SelectInput,
    DatePicker,
  },
  props: {
    quizId: {
      type: Number,
      required: true,
    }
  },
  setup(props, ctx) {
    let activityList = ref<Array<LogItem>>([]);
    let pagination = ref<metaInterface | null>(null);
    let page = ref<number>(1);
    let from = ref<null | Date>(null);
    let to = ref<null | Date>(null);

    let userListOption = ref<Array<{ label: string, value: string }>>([]);
    let filteredUserId = ref<undefined | string>();

    let questionListOption = ref<Array<{ label: string, value: string }>>([]);
    let filteredQuestionId = ref<undefined | string>();


    let tzAbbreviation = new Date().toLocaleTimeString('en-us', {timeZoneName: 'long'}).split(' ').slice(2).map(e => e[0]).join('');

    const fromToData = ref({
      dateInput: {
        placeholder: "Select Date",
        inputClass: "question-activity-date-picker",
      },
      format: "DD MM YYYY",
      sameDateFormat: {
        from: "YYYY MM DD",
        to: "YYYY MM DD",
      },
    })

    onMounted(() => {
      apiGetQuizActivityLogs(props.quizId, 1, from.value, to.value, filteredUserId.value)
        .then(res => {
          activityList.value = res.data.data;
          pagination.value = res.data.meta;
        })
        .catch(e => {
          console.error('something went wrong')
        })

      getUserList()
        .then((userList) => {
          userListOption.value = userList.data.data.map((user) => ({
            value: user.id.toString(),
            label: user.name
          }));
        });
      apiGetQuiz(props.quizId)
        .then((quiz) => {
          questionListOption.value = quiz.questions.map(question => ({
            label: question.text,
            value: question.id.toString()
          }))
        })

      initCloseIconOnCalendar()

    });

    function initCloseIconOnCalendar () {
      const calendarInput =
        document.getElementsByClassName('question-activity-date-picker')[0] as HTMLElement;
      const calendarActionSection = document.getElementsByClassName('vdpr-datepicker__calendar-actions')[0]
      const closeButton = document.createElement('div');
      closeButton.addEventListener('click', function () {
        calendarInput.click();
      });
      calendarActionSection.append(closeButton)
      closeButton.setAttribute('class', 'vdpr-datepicker__button vdpr-datepicker__button--block vdpr-datepicker__button-submit');
      closeButton.innerText = 'Close'
    }

    function navigateToPage(nextPage: number) {
      page.value = nextPage;
      apiGetQuizActivityLogs(props.quizId, nextPage, from.value, to.value, filteredUserId.value,  filteredQuestionId.value)
        .then(res => {
          activityList.value = res.data.data;
          pagination.value = res.data.meta;
          window.scrollTo(0, 0);
        })
        .catch(e => {
          console.error('something went wrong')
        })
    }

    function onFilterByDates(nextFrom: Date, nextTo: Date) {
      from.value = nextFrom;
      to.value = nextTo;
      page.value = 1;
      apiGetQuizActivityLogs(
        props.quizId,
        1,
        nextFrom,
        nextTo,
        filteredUserId.value,
        filteredQuestionId.value
      )
        .then(res => {
          activityList.value = res.data.data;
          pagination.value = res.data.meta;
          window.scrollTo(0, 0);
        })
        .catch(e => {
          console.error('something went wrong')
        })
    }

    function onSelectFilteredUser(selectedUser: string) {
      filteredUserId.value = selectedUser;
      page.value = 1;
      apiGetQuizActivityLogs(
        props.quizId,
        1,
        from.value,
        to.value,
        filteredUserId.value,
        filteredQuestionId.value
      )
        .then(res => {
          activityList.value = res.data.data;
          pagination.value = res.data.meta;
          window.scrollTo(0, 0);
        })
        .catch(e => {
          console.error('something went wrong')
        })

    }

    function onSelectFilteredQuestion(selectedQuestion: string) {
      filteredQuestionId.value = selectedQuestion;
      page.value = 1;
      apiGetQuizActivityLogs(
        props.quizId,
        1,
        from.value,
        to.value,
        filteredUserId.value,
        selectedQuestion
      )
        .then(res => {
          activityList.value = res.data.data;
          pagination.value = res.data.meta;
          window.scrollTo(0, 0);
        })
        .catch(e => {
          console.error('something went wrong')
        })

    }

    const getOldNewMap = (logItem: LogItem) => {
      const oldValues = logItem.properties.old;
      const newValues = logItem.properties.attributes;
      const result: {
        action: string
        oldValue: string,
        newValue: string
      }[]
       = [];
      const findIn = ['text', 'type', 'category_id', 'position']
      oldValues && Object.keys(oldValues).forEach(key => {
        const oldV = oldValues && oldValues[key as any] && Array.isArray(oldValues[key as any]) ? oldValues[key as any].join(', ') : oldValues[key as any];
        const newV =  newValues[key as any] && Array.isArray(newValues[key as any]) ? newValues[key as any].join(', ') : newValues[key as any];

        if(logItem.event === 'answered') {
          result.push({
            action: 'changed answer',
            oldValue: oldV,
            newValue: newV
          })

        } else if(logItem.event === 'updated') {

          if(oldV !== newV && ( findIn.indexOf(key) !== -1)) {

            result.push({
              action: `changed ${key}`,
              oldValue: oldV,
              newValue: newV
            })
          }
        }

      })

      return result
    }

    return {
      activityList,
      pagination,
      navigateToPage,
      fromToData,
      onFilterByDates,
      formatInTimeZone,
      tzAbbreviation,
      userListOption,
      onSelectFilteredUser,
      filteredUserId,

      onSelectFilteredQuestion,
      questionListOption,
      filteredQuestionId,
      getOldNewMap,
    }
  }
})
