
import { defineComponent } from 'vue';
import documentGradients from '@/constants/documentGradients';

export default defineComponent({
  name: 'TheGradient',
  props: {
    gradientType: {
      type: Number,
      required: true,
      validator(val: number) {
        return Object.keys(documentGradients).includes(val.toString(10));
      },
    },
    width: {
      type: Number,
      default: 16
    }
  },
});
