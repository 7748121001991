
import {computed, defineComponent, onBeforeUnmount, onMounted, ref} from 'vue';
import Pagination from '@/components/Pagination.vue';
import { IQuiz } from '@/types/quiz';
import {
  routeNewDocument,
  routeUpload
} from '@/core/constants';
import {
  apiGetQuiz,
  archiveQuizApi,
  getQuizFilters,
  GetQuizRequestFiltersPayload,
  searchQuizApi
} from '@/api/QuizApi';
import {useRoute, useRouter} from 'vue-router';
import QuizFilters from "@/components/QuizFilters.vue";
import {useStore} from 'vuex';
import {key} from '@/store';
import BaseButton from '@/components/BaseButton.vue';
import DocumentCardCheckbox from '@/components/DocumentCardCheckbox.vue';
import {ulid} from 'ulid';
import {apiCancelDownloadPartnerData, apiDownloadPartnerData} from '@/api/importApi';
import {echo} from '@/main';
import ProgressBar from '@/components/ProgressBar.vue';
import GrayText from '@/components/GrayText.vue';

export default defineComponent({
  name: 'BulkDownloadSelectedPartners',
  components: {
    GrayText, ProgressBar,
    QuizFilters,
    DocumentCardCheckbox,
    Pagination,
    BaseButton,
  },
  setup() {

    const route = useRoute();
    const router = useRouter();
    const store = useStore(key);
    const savedQuizIds = computed(() => store.state.createQuestion.quizIds);
    const quizPayload = computed(() => store.state['getQuizPayload']);

    const numberOfPartnersThatQuestionBeenSaved = ref<number>(0);
    const {
      params: { id },
    } = route;
    const questionCreateConfirmIsOpen = ref(false);
    const rNewDocument = ref(routeNewDocument);
    const documents = ref<IQuiz[]>([]);
    const pageMeta = ref({
      current_page: null,
      last_page: null,
      from: null,
      to: null,
      total: null,
    });
    const businessFunctionId = ref(-1);

    onMounted(() => {
      getQuizFilters().then(res => {
        businessFunctionId.value = res['business_function'].question_template_id;
      });
    })

    const handleQuizResponse = (res: any) => {
      documents.value = res.data.data;
      pageMeta.value = res.data.meta;
    };

    const getQuizzes = (page = 1) => {
      const payload = {
        ...quizPayload.value,
        page,
      }
      store.commit('setQuizPayload', payload);
      searchQuizApi(payload).then(handleQuizResponse);
    };
    onMounted(() => {
      searchQuizApi(quizPayload.value).then(handleQuizResponse);
    });
    const toggleQuiz = (quizId: number) => {
      store.commit('quizIdsToggle', quizId);
    };
    const handleSearch = (searchInputValue: string): void => {
      const payload = {
        ...quizPayload.value,
        name: searchInputValue,
        page: 1,
      }
      store.commit('setQuizPayload', payload)

      searchQuizApi(payload).then(handleQuizResponse);
    };
    const navigateToPage = (page: number) => {
      getQuizzes(page);
    };

    const archiveQuiz = (quizId: number) => {
      archiveQuizApi(quizId).then(() => {
        getQuizzes(pageMeta.value.current_page || 1);
      });
    };

    function onFilterChange(data: GetQuizRequestFiltersPayload) {
      const payload = {
        ...quizPayload.value,
        ...data,
        page: 1,
      }
      store.commit('setQuizPayload', payload);
      searchQuizApi(payload).then(handleQuizResponse);
    }

    function save() {
      const noPartnersSelected =  savedQuizIds.value.length === 0;
      if(noPartnersSelected) {
        alert('Select at least one partner.')
        return;
      }
      if(isAllPartnerDataDownloading.value ) {
        return;
      }
      downloadAllPartnerData()
    }

    const isAllPartnerDataDownloading = ref(false);
    const download_id = ref<null | string>(null);
    let downloadAbortController = new AbortController();
    const downloadProgress = ref<number>(0);

    type QuizProgressEventType ={data: {current: number, total: number, request_id: string}};

    onMounted(() => {
      echo?.channel('quiz')
        .listen('.quiz.export_progress', (
          {
            data : {
              current,
              total,
              request_id
            }
          } : QuizProgressEventType
        ) => {
          if(request_id === download_id.value) {
            const onePercent = total / 100;
            const progress = current / onePercent;
            downloadProgress.value = progress / 100;
          }
        })
    })

    const downloadAllPartnerData = () => {
      isAllPartnerDataDownloading.value = true;

      download_id.value = ulid();

      apiDownloadPartnerData(download_id.value, downloadAbortController.signal, savedQuizIds.value)
        .then(response => {
          const blob = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = blob;
          link.setAttribute('download', response.headers?.filename);
          document.body.appendChild(link);
          link.click();
          store.commit('setCreatedQuestionInitialState');
          store.commit('setQuestionEditMode', false);
          router.push({
            name: routeUpload,
          });
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          isAllPartnerDataDownloading.value = false;
          downloadProgress.value = 0;
        });
    }

    const abortDownload = () => {
      downloadAbortController.abort();
      isAllPartnerDataDownloading.value = false;
      downloadProgress.value = 0;

      if(download_id.value) {
        apiCancelDownloadPartnerData(download_id.value)
          .then(() => {
            download_id.value = null;
          })
      }

      downloadAbortController = new AbortController()
    }

    onBeforeUnmount(() => {
      abortDownload();
    })

    function cancel() {
      store.commit('quizIdsSet', []);
      router.push({
        name: routeUpload,
      });
    }

    function selectAll() {
      store.commit('quizIdsAdd', documents.value.map(el => el.id));
    }
    function deselectAll() {
      store.commit('quizIdsOmit', documents.value.map(el => el.id));
    }
    return {
      documents,
      rNewDocument,
      handleSearch,
      navigateToPage,
      archiveQuiz,
      pageMeta,
      onFilterChange,
      businessFunctionId,

      numberOfPartnersThatQuestionBeenSaved,

      save,
      cancel,

      questionCreateConfirmIsOpen,
      savedQuizIds,
      toggleQuiz,

      selectAll,
      deselectAll,

      isAllPartnerDataDownloading,
      downloadProgress,
      abortDownload,
    };
  },
});
