
import {computed, defineComponent, onMounted, ref, watch} from 'vue';
import Question from '@/components/Question/Question.vue';
import { IQuestion, questionTypes, questionAnswerValues } from '@/types/question';
import { apiGetQuiz } from '@/api/QuizApi';
import { useRoute } from 'vue-router';
import {
  apiEditQuestion,
  apiReorderQuestions,
  ReorderQuestionPayload,
} from '@/api/QuestionApi';
import {
  Category,
} from '@/api/categoryApi';
import createTextQuestion, {NEW_QUESTION_ID} from '@/models/questionText';
import { ulid } from 'ulid';
import Draggable from 'vuedraggable';
import CienaLayout from "@/components/CienaLayout.vue";
import QuestionHeader from "@/components/QuestionHeader.vue";
import {key} from "@/store";
import {useStore} from "vuex";
import QuestionCategory from "@/components/QuestionCategory.vue";

import QuestionAnswer from '@/components/Question/QuestionAnswer/QuestionAnswer.vue';
import { apiAnswerQuiz } from '@/api/QuizApi';

import { onBeforeRouteLeave } from 'vue-router';
import { notify } from '@kyvg/vue3-notification';
import {ACTIVITY_ID, ATTACHMENTS_ID} from '@/core/constants';
import QuestionActivity from '@/components/QuestionActivity.vue';
import QuestionAttachments from '@/components/QuestionAttachments.vue';
import AddQuestionToQuizModal from '@/components/AddQuestionToQuizModal.vue';
import RemoveQuestionFromQuizModal from '@/components/RemoveQuestionFromQuizModal.vue';
import EditQuestionQuizModal from '@/components/EditQuestionQuizModal.vue';

export default defineComponent({
  name: 'QuestionPage',
  components: {
    CienaLayout,
    Question,
    Draggable,
    QuestionHeader,
    QuestionCategory,
    QuestionAnswer,
    QuestionActivity,
    QuestionAttachments,
    AddQuestionToQuizModal,
    RemoveQuestionFromQuizModal,
    EditQuestionQuizModal,
  },
  setup() {
    const store = useStore(key);
    const route = useRoute();
    const {
      params: { id },
    } = route;
    const quizId = parseInt(id.toString(), 10);
    const quiz = ref();
    const questions = ref<IQuestion[]>([]);
    const editingQuestionId = ref<number | null>(null);
    const editingRulesQuestionId = ref<number | null>(null);
    const questionList = ref(null);
    const drag = ref(false);

    const removeQuestion = ref({
      questionId: -1,
      questionIndex: -1,
      name: '',
      isConfirmRemoveQuestionOpened: false,
    });

    const formErrors = ref<Record<number, string[]>>({});

    const currentCategory = computed(() => store.state.currentCategory);
    const categories = computed((): Category[] => store.state.categories)
    const isQuestionEditMode = computed(() => store.state.isQuestionsEditMode);
    const newQuestionPositionIndex = computed(
      () => store.state.createQuestion.questionPositionIndex
    );
    const newQuestion = computed(() => store.state.createQuestion.question);

    const storeDeleteQuestionData = computed(() => store.state.deleteQuestion);

    const editQuestionStoreData = computed(() => store.state.editQuestion);
    onMounted(() => {
      apiGetQuiz(quizId).then((res) => {
        questions.value = res.questions;
        quiz.value = res;
        if(newQuestionPositionIndex.value !== null && newQuestion.value !== null) {
          addCreatedQuestionToStore(newQuestion.value, newQuestionPositionIndex.value);
        }
        calculateRenderIx();
      });
    });

    const getQuestionIndexById = (questionId: number): number => {
      let questionIndex = -1;
      questions.value.forEach((question, ix) => {
        if (question.id === questionId) {
          questionIndex = ix;
        }
      });
      return questionIndex;
    };

    const updateQuestion = async (questionIndex: number) => {
      //@todo should be changed and triggered on button press
      const updatedQuestion = await apiEditQuestion(
        quizId,
        questions.value[questionIndex].id,
        questions.value[questionIndex]
      );
      questions.value[questionIndex].id = updatedQuestion.data.data.id;
      // editingQuestionId.value = updatedQuestion.data.data.id;
    };

    const setPositions = () => {
      const payload: ReorderQuestionPayload = [];

      for (let i = 0; i < questions.value.length; i += 1) {
        const newPosition = i + 1;
        const data: { id: number; position: number } = {
          id: questions.value[i].id,
          position: newPosition,
        };
        questions.value[i].position = newPosition;
        payload.push(data);
      }
      if (payload.length) {
        apiReorderQuestions(quizId, {
          questions: payload,
        });
      }
    };

    const addCreatedQuestionToStore = (res: IQuestion, ix: number) => {
      questions.value.splice(ix, 0, res);
      // setPositions();
    };

    const handleAddQuestion = (ix: number, position: number) => {
      if(!currentCategory.value) throw new Error('Not category chosen');

      const newMockQuestion = createTextQuestion(position, currentCategory.value);
      addCreatedQuestionToStore(newMockQuestion, ix + 1);
      handleQuestionClickFromAnswerPage(newMockQuestion.id);
      store.commit('setInitiateCreateQuestion', true);
    };



    const isQuestionActive = (questionId: number) =>
      editingQuestionId.value !== null && questionId === editingQuestionId.value;


    const handleTypeChange = (ix: number, val: questionTypes) => {
      const prevQuestionType = questions.value[ix].type;

      const newQuestionType = val;
      questions.value[ix].type = val;
      // mulitple => yes_no || text
      // select    => yes_no || text
      // yes_no  => multiple || select || text
      if (
        (prevQuestionType === 'multiple' &&
          (newQuestionType === 'yes_no' || newQuestionType === 'text')) ||
        (prevQuestionType === 'drop_down' &&
          (newQuestionType === 'yes_no' || newQuestionType === 'text')) ||
        (prevQuestionType === 'yes_no' &&
          (newQuestionType === 'drop_down' ||
            newQuestionType === 'text' ||
            newQuestionType === 'multiple'))
      ) {
        // questions.value[ix].ruling = [] @todo identify rulling structure
      }

      if (newQuestionType === 'text') {
        questions.value[ix].answer_values = {};
      }
      if (newQuestionType === 'date') {
        questions.value[ix].answer_values = {};
      }
      if (prevQuestionType === 'text') {
        questions.value[ix].answer_values = {
          [ulid()]: '',
        };
      }

      if (newQuestionType === 'yes_no') {
        questions.value[ix].answer_values = {
          [ulid()]: 'No',
          [ulid()]: 'Yes',
        };
      }
    };

    const handleQuestionTitleChange = (ix: number, val: string) => {
      questions.value[ix].text = val;
    };

    const updateAnswerOptions = (ix: number, q: questionAnswerValues) => {
      questions.value[ix].answer_values = q;
    };

    const handleRemoveQuestion = (ix: number) => {
      store.commit('setInitiateDeleteQuestion', {
        isDeleteQuestionOpen: true,
        questionPositionIndex: ix,
        question: questions.value[ix],
        isSaveNowQuestionLocationOpen: false,
        quizIds: [],
      });
    };

    const handleAddRule = (ix: number) => {
      const questionId = questions.value[ix].id;
      editingRulesQuestionId.value = questionId;
    };

    const isEditingQuestionRule = (questionId: number) =>
      questionId === editingRulesQuestionId.value;

    const getAllQuestionsIfIsEditingRule = (questionId: number) => {
      let res = null;
      if (isEditingQuestionRule(questionId)) {
        res = questions.value;
      }
      return res;
    };

    const handleCancelRule = () => {
      editingRulesQuestionId.value = null;
    };

    const handleSaveRule = (ix: number, rules: any) => {
      questions.value[ix].rules = rules;
      const question = questions.value[ix];
      onSaveEditedQuestion(question);
      editingRulesQuestionId.value = null;
    };

    const removeAnswerOptionRule = (index: number, uuid: string) => {
      let elIx = -1;
      questions.value[index].rules.forEach((el, ix) => {
        if (el.answer_value_key === uuid) {
          elIx = ix;
        }
      });
      questions.value[index].rules.splice(elIx, 1);
    };
    const changeQuestionWithPlaces = (oldIndex: number, newIndex: number) => {
      const temp = JSON.parse(JSON.stringify(questions.value[newIndex]));
      questions.value.splice(newIndex, 1);
      questions.value.splice(oldIndex, 0, temp);
    };

    const isQuestionUnderRuleIx = (ix: number) => {
      const questionId = questions.value[ix].id;
      let ret = -1;
      questions.value.forEach((question, index) => {
        question.rules.forEach((rule) => {
          rule.question_ids.forEach(ruleQuestionId => {
            if (ruleQuestionId === questionId) {
              ret = index;
            }
          })

        });
      });
      return ret;
    };

    const nearestRuleOfQuestionIx = (ix: number): number => {
      if (!(questions.value[ix].rules.length === 0)) {
        let ruleQuestionIds: number[] = [];
        questions.value[ix].rules.forEach((rule) =>
          // parseInt(rule.question_id, 10)
          rule.question_ids.forEach(questionId => {
            ruleQuestionIds.push(questionId);
          })
        );
        const arrayOfIx: Array<number> = [];
        questions.value.forEach((question, index) => {
          if (ruleQuestionIds.indexOf(question.id) !== -1) {
            arrayOfIx.push(index);
          }
        });
        return arrayOfIx[0];
      }
      return -1;
    };

    const onDragChange = (change: any) => {
      const {
        moved: { newIndex, oldIndex },
      } = change;

      const isUnderRule = isQuestionUnderRuleIx(newIndex);
      const nearestUnderRule = nearestRuleOfQuestionIx(newIndex);
      if (isUnderRule > newIndex) {
        changeQuestionWithPlaces(oldIndex, newIndex);
        // eslint-disable-next-line no-alert
        // alert('Elementul la care sa facut drag este mai sus decit cel la care se afla in rule.');
      } else if (nearestUnderRule !== -1 && nearestUnderRule <= newIndex) {
        console.log('2');
        changeQuestionWithPlaces(oldIndex, newIndex);
        // eslint-disable-next-line no-alert
        // alert('Elementul la care sa facut drag este mai jos decit cel la care il are ca rule.');
      } else {
        setPositions();
      }
    };




    const questionIdsToHide = ref<number[]>([]);

    // check if the question in under rule and match the condition to be pushed;

    const handleQuestionIxToRenderNew = (): number[] => {
      const theLength = questions.value.length;
      const questionsToHide: number[] = [];
      const questionIndexes: Record<string, number> = {}
      questions.value.forEach((q, ix) => {
        questionIndexes[String(q.id)] = ix;
      })
      for (let i = 0; i < theLength; i += 1) {
        const question = questions.value[i];

        const haveRules = question.rules.length > 0;
        const isRuleableType =
          question.type === 'multiple' || question.type === 'yes_no' || question.type === 'drop_down';

        const haveAnswer = question.user_answer !== null;
        const userAnswerIsArray = Array.isArray(question.user_answer);

        if (haveAnswer && haveRules && isRuleableType) {
          let answers: string[] = [];

          if (userAnswerIsArray) {
            answers = question.user_answer as string[];
          } else {
            answers = [question.user_answer as string];
          }

          answers.forEach(answer => {
            question.rules.forEach(rule => {
              if (rule.answer_value_key === answer) {
                rule.question_ids.forEach(questionId => {
                  const currentQuestionPosition =
                    rule.owner_question_id ? questionIndexes[rule.owner_question_id] : -1;

                  const questionToHidePosition = questionIndexes[questionId];
                  const isHiddenQuestionLowerThenCurrent =
                    currentQuestionPosition < questionToHidePosition;

                  if(isHiddenQuestionLowerThenCurrent) {
                    questionsToHide.push(questionId);
                  } else {
                    console.log(`Question "${question.text}" have under rule question "${questions.value[questionIndexes[questionId]].text}" which is upper then it!!`)
                  }

                })
              }
            })
          })

        }
      }
      console.log({questionsToHide});
      return questionsToHide;

    };

    const calculateRenderIx = () => {
      questionIdsToHide.value =  handleQuestionIxToRenderNew();
    };


    onBeforeRouteLeave((to, from, next) => {
      const visibleQuestions: IQuestion[] = questions.value.filter(
        (question)  => questionIdsToHide.value.indexOf(question.id) === -1,
      );

      const requiredQuestions = visibleQuestions.filter(
        (question): boolean => question.is_required && (question.user_answer === null),
      );
      const requiredUnansweredQuestionsLength = requiredQuestions.length;
      if (requiredUnansweredQuestionsLength && !isQuestionEditMode.value) {
        store.commit('setInitiateCreateQuestion', false);
        const alertMessage = `<h6>Partner info is incomplete.</h6><br/> ${requiredQuestions.map((question) => question.text).join('<br/>')} <br/>${requiredUnansweredQuestionsLength === 1 ? 'Is' : 'Are'} required.`;

        const isRedirectedDeleteQuestionSelectPartner =
          storeDeleteQuestionData
            .value
            .isDeleteQuestionOpen;

        if (!isRedirectedDeleteQuestionSelectPartner) {
          notify({
            title: alertMessage,
            type: 'error',
            duration: 20000,
            closeOnClick: true,
          });
        }

      }
      next();
    });

    const handleQuestionClickFromAnswerPage = (questionId: number | null) => {
      editingQuestionId.value = questionId;
      setTimeout(() => {
        if(questionId !== null) {
          const yOffset = -100;
          const element = document.getElementById(questionId.toString());
          if(element) {
            const y = element?.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
          }

        }
      },300)
    }

    const handleQuestionClick = (questionId: number | null) => {
      editingQuestionId.value = questionId;
    };

    function debounce(func: () => any, timeout = 300){
      let timer: null | number;
      return (...args: any[]) => {
        timer && clearTimeout(timer);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
      };
    }


    const saveResponses = () => {
      const answer: { [key:number]: string | Array<string> } = {
      };
      questions.value.forEach((question) => {
        if (question.user_answer !== null) {
          answer[question.id] = question.user_answer;
        }
      });
      apiAnswerQuiz(quizId, answer)
        .then(() => {
          formErrors.value = {};
        })
      .catch(e => {
        const { errors, message } = e.response.data;
        formErrors.value = errors;
        notify({
          title: message,
          type: 'error',
          duration: 8000,
          closeOnClick: true,
        });

      });
      calculateRenderIx();
    };
    const saveResponseWithDebounce = debounce(() => saveResponses(), 1000);

    const handleValueUpdate = (questionId: number, val: string) => {
      const questionIX = getQuestionIndexById(questionId);
      questions.value[questionIX].user_answer = val;
      saveResponseWithDebounce();
    };

    const toNextCategory = () => {
      if(currentCategory.value !== null)
      store.commit('setCurrentCategory', currentCategory.value + 1);
      window.scrollTo(0,0);
    };

    const onCancelAddedQuestion = (ix: number) => {
      store.commit('setInitiateCreateQuestion', false);
      questions.value.splice(ix, 1);
    };

    const onSaveAddedQuestion = (questionIndex: number) => {
      const questionData = questions.value[questionIndex];
      store.commit('setCreatedQuestion', questionData);
      store.commit('setCreatedQuestionIndex', questionIndex);
      store.commit('setOpenSaveNowQuestionLocationOpen', true);
    };


    const onSaveEditedQuestion = (question: IQuestion) => {
      store.commit('setInitiateEditQuestion', {
        isEditQuestionOpen: true,
        question: question,
        quizIds: [],
      })
    }

    const onCancelEditedQuestion = (question: IQuestion) => {
      handleQuestionClick(null);
      const questionIndex = getQuestionIndexById(question.id);
      questions.value[questionIndex] = question;
    }

    const getQuestionCount = (questionId: number): number => {
      const questionIds =  questions.value.map(q => q.id);

      questionIdsToHide.value.forEach(hiddenQuestionId => {
        let elToRemove = questionIds.indexOf(hiddenQuestionId);
        questionIds.splice(elToRemove, 1);
      })

      return questionIds.indexOf(questionId) + 1;
    }

    return {
      questions,
      quiz,
      editingQuestionId,
      handleTypeChange,
      handleQuestionClick,
      handleQuestionClickFromAnswerPage,
      isQuestionActive,
      handleAddQuestion,
      handleQuestionTitleChange,
      updateAnswerOptions,
      handleRemoveQuestion,
      handleAddRule,
      isEditingQuestionRule,
      getAllQuestionsIfIsEditingRule,
      handleCancelRule,
      handleSaveRule,
      questionList,
      removeAnswerOptionRule,
      drag,
      onDragChange,
      isQuestionEditMode,


      handleValueUpdate,
      questionIdsToHide,
      removeQuestion,
      currentCategory,
      toNextCategory,
      categories,

      formErrors,
      ACTIVITY_ID,
      quizId,
      ATTACHMENTS_ID,

      onSaveAddedQuestion,
      onCancelAddedQuestion,

      storeDeleteQuestionData,

      onSaveEditedQuestion,
      onCancelEditedQuestion,

      getQuestionCount,
    };
  },
});
