
import { defineComponent, onMounted, ref} from 'vue';
import { format } from 'date-fns';
import TheGradient from '@/components/TheGradient.vue';
import RemoveAttachmentSvg from '@/assets/img/remove-attachment.vue';
import {
  addQuizFilesApi, apiDownloadQuizAttachment,
  deleteQuizFilesApi,
  getQuizFilesApi,
  QuizFileAttachment
} from '@/api/quizFileApi';
import UploadBox from '@/components/UploadBox.vue';
import UploadPageProgress from '@/components/UploadPageProgress.vue';
import TheModal from '@/components/TheModal.vue';
import useModal from '@/hooks/useModal';
import BaseButton from '@/components/BaseButton.vue';
import {AxiosError} from 'axios';
import TitleText from '@/components/TitleText.vue';
import DownloadSvg from '@/assets/img/download.vue';
import {useRoute} from 'vue-router';
import {apiGetQuiz} from '@/api/QuizApi';
import {IQuiz} from '@/types/quiz';
import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue';

export default defineComponent({
  name: 'QuestionAttachments',
  components:{
    DownloadSvg,
    TheGradient,
    UploadBox,
    UploadPageProgress,
    TheModal,
    BaseButton,
    TitleText,
    RemoveAttachmentSvg,
    ConfirmDeleteModal,
  },
  props: {
    quizId: {
      type: Number,
      required: true,
    }
  },
  setup(props) {
    const route = useRoute();
    const questionId = parseInt(route.params.id.toString(), 10);
    const name = ref('');
    onMounted(() => {
      apiGetQuiz(questionId).then((res: IQuiz | any) => {
        name.value = res.name;
      });
    });

    let fileList = ref<QuizFileAttachment[]>([]);
    const errorMessage = ref('');

    const fromToData = ref({
      dateInput: {
        placeholder: "Select Date",
        inputClass: "red",
      },
      format: "DD MM YYYY hh:mm",
      sameDateFormat: {
        from: "DD MM YYYY, hh:mm",
        to: "hh:mm",
      },
    })

    const uploadCompletedModal = useModal(false);
    const errorModal = useModal(false);

    const confirmDeleteFilesModal = useModal(false);

    const deleteId = ref<null | number>(null);
    const uploadProgress = ref(100)

    onMounted(() => {
      _fetchFiles();
    });

    function _fetchFiles() {
      getQuizFilesApi(props.quizId)
        .then(res => {
          fileList.value = res.data.data;
        }).catch(console.error)
    }

    function onFileChosen(file: Blob) {
      addQuizFilesApi(props.quizId, file, onUploadingFile)
        .then(() => {
          _fetchFiles();
          uploadCompletedModal.open();
        }).catch(handleResponseError)
    }
    function onUploadingFile(progressEvent: ProgressEvent) {
      uploadProgress.value =  Math.round((progressEvent.loaded * 100) / progressEvent.total);

    }
    const handleResponseError = (e: AxiosError) => {
      errorMessage.value = e?.response?.data?.message || 'Something went wrong!';
      errorModal.open();
    };

    function openDeleteModal(id: number, event: Event) {
      event.stopPropagation();
      deleteId.value = id;
      confirmDeleteFilesModal.open();
    }


    const importAndReplace = () => {
      const id = deleteId.value;
      if(!id) throw new Error("Don't have id to perform this action!")

      deleteQuizFilesApi(props.quizId, id)
        .then(() => {
          handleConfirmDeleteFilesModalClose();
          _fetchFiles();
        })
        .catch(handleResponseError);
    }
    function handleConfirmDeleteFilesModalClose () {
      confirmDeleteFilesModal.close();
      deleteId.value = null;
    }

    function downloadFile (file: QuizFileAttachment, e: Event) {
      e.stopPropagation();
      apiDownloadQuizAttachment(props.quizId, file.id)
        .then(res => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(new Blob([res.data]));
          link.download = file.name;
          link.click();
          URL.revokeObjectURL(link.href);
        });
    }
    return {
      fileList,
      format,
      onFileChosen,
      errorMessage,
      uploadCompletedModal,
      errorModal,
      openDeleteModal,
      confirmDeleteFilesModal,
      importAndReplace,
      handleConfirmDeleteFilesModalClose,
      uploadProgress,
      downloadFile,
      fromToData,
      name,
    }
  }
})
