<template>
  <div class="d-flex">
    <radio-button
      label="Yes"
      value="Yes"
      class="mr-10"
      :is-active="false"
    />
    <radio-button
      label="No"
      value="No"
      :is-active="false"
    />
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import RadioButton from '@/components/RadioButton.vue';

export default defineComponent({
  name: 'OptionYesNo',
  components: {
    RadioButton,
  },
});
</script>
