
import { computed, defineComponent, PropType, reactive, ref } from 'vue';
import { questionTypes } from '@/types/question';
import TypeTextSvg from '@/assets/img/type-text.vue';
import TypeYesNoSvg from '@/assets/img/type-yesno.vue';
import TypeDropdownSvg from '@/assets/img/type-dropdown.vue';
import TypeMultiselectSvg from '@/assets/img/type-multiple.vue';
import ChevronDownSvg from '@/assets/img/chevron-down.vue';
import { onClickOutside } from '@vueuse/core';

export default defineComponent({
  name: 'QuestionTypeSelector',
  components: {
    ChevronDownSvg,
  },
  emits: ['handleTypeChange'],
  props: {
    value: {
      type: String as PropType<questionTypes>,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, ctx) {
    const questionTypeRef = ref(null);
    const isDropDownOpen = ref(false);
    const options = reactive([
      {
        label: 'Text Answer', value: 'text',
      },
      {
        label: 'Yes or No', value: 'yes_no',
      },
      {
        label: 'Dropdown', value: 'drop_down',
      },
      {
        label: 'Multiple Choice', value: 'multiple',
      },
      {
        label: 'Date', value: 'date',
      },
    ]);

    const svgCorrespondingToNames = reactive({
      text: 'type-text-svg',
      yes_no: 'type-yes-no-svg',
      drop_down: 'type-dropdown-svg',
      multiple: 'type-multiselect-svg',
      date: 'type-multiselect-svg',
    });

    const availableOptions = computed(() => options.filter((x) => x.value !== props.value));
    const svgType = computed(() => svgCorrespondingToNames[props.value]);
    const label = computed(() => {
      let labelName = '';
      switch (props.value) {
        case 'text':
          labelName = 'Text Answer';
          break;
        case 'yes_no':
          labelName = 'Yes or No';
          break;
        case 'drop_down':
          labelName = 'Dropdown';
          break;
        case 'multiple':
          labelName = 'Multiple Choice';
          break;
        case 'date':
          labelName = 'Date';
          break;
        default:
      }
      return labelName;
    });
    const closeDropDownFn = () => {
      isDropDownOpen.value = false;
    };
    const openDropDownFn = () => {
      if(props.isActive) {
        isDropDownOpen.value = true;
      }

    };
    const handleTypeChangeFn = (value: questionTypes) => {
      ctx.emit('handleTypeChange', value);
      closeDropDownFn();
    };

    onClickOutside(questionTypeRef, (event) => {
      closeDropDownFn();
    });
    return {
      svgType,
      label,
      availableOptions,
      svgCorrespondingToNames,
      handleTypeChangeFn,
      openDropDownFn,
      isDropDownOpen,
      questionTypeRef,
    };
  },
});
