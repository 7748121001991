import req from '@/api/utilities/axios';
import { IQuestion, ApiQuestionResponse, ApiOkResponse } from '@/types/question';
import { ApiQuizResponse } from '@/types/quiz';


export const apiCreateQuestion = (
  quizId: number,
  payload: IQuestion
): ApiQuestionResponse => req.post(`quiz/${quizId}/question/`, payload);


export interface ApiCreateQuestionNewPayload {
  question: IQuestion,
  partner_ids: Array<number>
}
export const apiCreateQuestionNew = (
  quizId: number,
  payload: ApiCreateQuestionNewPayload
): ApiQuestionResponse => {
  return req.post(`quiz/${quizId}/question/`, {
    partner_ids: payload.partner_ids,
    ...payload.question,
  });
};


export const apiEditQuestion = (
  quizId: number,
  questionId: number,
  payload: Partial<IQuestion>
): ApiQuestionResponse =>
    req.put(`quiz/${quizId}/question/${questionId}`, payload);

export const apiRemoveQuestion = (
  quizId: number,
  questionId: number
): ApiOkResponse =>
  req.delete(`quiz/${quizId}/question/${questionId}`);

export const apiRemoveQuestionNew = (
  quizId: number,
  questionId: number,
  partnerIds: number[]
): ApiOkResponse =>
  req.delete(`quiz/${quizId}/question/${questionId}`, {
    data: {partner_ids: partnerIds}
  });

export type ReorderQuestionPayload = Array<{ id: number; position: number }>;
export const apiReorderQuestions = (
  quizId: number,
  payload: {questions: ReorderQuestionPayload}
): ApiQuizResponse =>
  req.post(`quiz/${quizId}/reorder`, payload);

interface UpdateQuestionCategoryDto  {
  categoryId: number;
  questionId: number;
  quizId: string;
}
export const updateQuestionCategoryApi = ({
     categoryId,
     questionId,
                                            quizId,
   }: UpdateQuestionCategoryDto) =>
  req.put(`quiz/${quizId}/question/${questionId}/category`, {
    category_id: categoryId,
    question_id: questionId
  })


export const apiEditQuestionNew = (
  quizId: number,
  questionId: number,
  partner_ids: number[],
  question: Partial<IQuestion>,
  keep_answer: boolean,
): ApiOkResponse =>
  req.put(`quiz/${quizId}/question/${questionId}`, {
    partner_ids,
    ...question,
    keep_answer,
  });
