import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-button-icon" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`card-button ${$props.cardBackground}`),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('handleClick'))),
    onKeydown: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('handleClick')), ["enter"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "icon")
    ]),
    _createElementVNode("span", {
      class: "card-button-caption fw-light",
      innerHTML: $props.name
    }, null, 8, _hoisted_2)
  ], 34))
}