

import {defineComponent, onMounted, ref} from "vue";
import {apiGetReport} from "@/api/reportApi";
import ReportQuadrant from "@/components/ReportQuadrant.vue";
import SelectInput from "@/components/SelectInput.vue";
import DropDown from "@/components/DropDown.vue";

export default defineComponent( {
  name: 'ReportPage',
  components: {DropDown, ReportQuadrant},
  setup() {
    const year = ref(new Date().getFullYear()); // default value from report
    const report = ref({});
    const yearList = ref<Array<number>>([
      2022,
      2023,
    ])

    onMounted(() => {
      getReportData()
    })

    function getReportData() {
      apiGetReport(year.value).then((data) => {
        report.value = data;
      })
    }

    function setYear(selectedYear: number) {
      year.value = selectedYear;
      getReportData()
    }

    return {
      year,
      report,
      yearList,
      setYear,
    }
  },

});
