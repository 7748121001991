import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vShow as _vShow, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-123f09e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "searchMultipleSelectWrapper",
  class: "dropdown"
}
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = { class: "dropdown-content" }
const _hoisted_4 = { class: "checkbox-place" }
const _hoisted_5 = {
  key: 1,
  class: "checkbox-border"
}
const _hoisted_6 = ["onMousedown"]
const _hoisted_7 = { class: "checkbox-place" }
const _hoisted_8 = {
  key: 1,
  class: "checkbox-border"
}
const _hoisted_9 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chevron_down_svg = _resolveComponent("chevron-down-svg")!
  const _component_checked_svg = _resolveComponent("checked-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isOpen = !_ctx.isOpen))
    }, [
      _createVNode(_component_chevron_down_svg, {
        class: _normalizeClass({
        'select-input-chevron': true,
        'select-input-chevron-open': _ctx.isOpen,
      })
      }, null, 8, ["class"]),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event)),
        class: "dropdown-input",
        placeholder: _ctx.inputPlaceholder
      }, null, 8, _hoisted_2), [
        [_vModelText, _ctx.searchValue]
      ])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "dropdown-item",
        onMousedown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleAll && _ctx.toggleAll(...args)))
      }, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.isAllSelected)
            ? (_openBlock(), _createBlock(_component_checked_svg, { key: 0 }))
            : (_openBlock(), _createElementBlock("span", _hoisted_5))
        ]),
        _createTextVNode(" Check All ")
      ], 32),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getSearchSelectInputOptions(_ctx.question.answer_values), (option, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "dropdown-item",
          onMousedown: ($event: any) => (_ctx.onItemClick(option.id))
        }, [
          _createElementVNode("div", _hoisted_7, [
            (_ctx.selectedItems.indexOf(option.id) !== -1)
              ? (_openBlock(), _createBlock(_component_checked_svg, { key: 0 }))
              : (_openBlock(), _createElementBlock("span", _hoisted_8))
          ]),
          _createTextVNode(" " + _toDisplayString(option.name), 1)
        ], 40, _hoisted_6))
      }), 128)),
      (_ctx.getSearchSelectInputOptions(_ctx.question.answer_values).length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, "No data"))
        : _createCommentVNode("", true)
    ], 512), [
      [_vShow, _ctx.isOpen]
    ])
  ], 512))
}