

import {defineComponent, onMounted, ref} from "vue";
import DropDown from "@/components/DropDown.vue";
import {apiGetReleaseNotes, ReleaseNotes} from '@/api/releaseNotesApi';
import TheGradient from '@/components/TheGradient.vue';
import {format} from 'date-fns';
import Pagination, {metaInterface} from '@/components/Pagination.vue';

export default defineComponent( {
  name: 'ReleaseNotes',
  methods: {format},
  components: {Pagination, TheGradient, DropDown},
  setup() {
    const page = ref<number>(1);
    const year = ref(new Date().getFullYear()); // default value from year
    const month = ref<number | null>(null); // default value from month
    const releaseNotes = ref<ReleaseNotes[]>([]);
    const pageMeta = ref<null | metaInterface>(null);
    const yearList = ref<Array<number>>([
      2022,
      2023,
      2024,
    ]);

    const monthList = ref<Array<{label: string, value: number}>>([
      {label: 'January', value: 1},
      {label: 'February', value: 2},
      {label: 'March', value: 3},
      {label: 'April', value: 4},
      {label: 'May', value: 5},
      {label: 'June', value: 6},
      {label: 'July', value: 7},
      {label: 'August', value: 8},
      {label: 'September', value: 9},
      {label: 'October', value: 10},
      {label: 'November', value: 11},
      {label: 'December', value: 12},
    ])
    onMounted(() => {
      getReleaseNotesData()
    })

    function getReleaseNotesData() {
      apiGetReleaseNotes(year.value, month.value, page.value).then((res) => {
        releaseNotes.value = res.data
        pageMeta.value = res.meta
      })
    }

    function setYear(selectedYear: number) {
      year.value = selectedYear;
      getReleaseNotesData()
    }

    function setMonth(selectedMonth: number) {
      month.value = selectedMonth;
      getReleaseNotesData()
    }

    function navigateToPage(nextPage: number) {
      page.value = nextPage;
      getReleaseNotesData();
    }
    return {
      releaseNotes,
      yearList,
      year,
      setYear,
      setMonth,
      month,
      monthList,
      pageMeta,
      navigateToPage,

    }
  },

});
