import { AxiosError, AxiosResponse } from 'axios';

export function handleResponse(response: AxiosResponse) {
  if (response.data) {
    return response.data.data;
  }

  return response;
}

export function handleError(error: AxiosError) {
  return error;
}
