

import {defineComponent} from "vue";
import UploadSvg from '@/assets/img/upload.vue';
interface Event<T = EventTarget> {
  target: T;
  // ...
}
export default defineComponent({
 name: 'UploadBox',
  components: {
   UploadSvg,
  },
  props: {
   text: {
     type: String,
      default: 'Upload file to begin import >>'
    },
  },
  emits: ['onChosen'],
  setup(props, context) {
    const chooseFile = () => {
      const input = document.createElement('input')
      input.setAttribute('type', 'file');
      input.addEventListener('change', onSelectingFile)
      input.click();

    }
    const onSelectingFile = (e: any) => {
      context.emit('onChosen', e.target.files[0]);
    }
    return {
      chooseFile,
    };
  }
})
