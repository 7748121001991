
import { computed, defineComponent, toRefs } from 'vue';
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
export default  defineComponent({
  components:{
    CircleProgress,
  },
  props: {
    progress: { type: Number, required: true }
  },
  setup(props) {
    // const { progress } = toRefs(props);
    //
    // const strokeDashoffset = computed((): number => {
    //   return 100 - (100 - progress.value);
    // })

    // const background = computed((): string => {
    //   const angle = 360 * progress.value;
    //   return `radial-gradient(#E1FFE4 60%, transparent 51%),
    //     conic-gradient(transparent 0deg ${angle}deg, #E1FFE4 ${angle}deg 360deg),
    //     conic-gradient(#21B02D 0deg, #21B02D 90deg, #21B02D 180deg, #21B02D)`;
    // })

    // Of course, adapt the code to the approach you choose
    // return { strokeDashoffset, background };
  },
});
