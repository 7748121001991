import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12b12bb0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hamburger = _resolveComponent("hamburger")!
  const _component_ciena_logo = _resolveComponent("ciena-logo")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_search_input_header = _resolveComponent("search-input-header")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isOpened)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "hamburger-open-sidebar",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onOpenSidebar'))),
          onKeydown: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('onOpenSidebar')), ["enter"]))
        }, [
          _createVNode(_component_hamburger)
        ], 32))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "header-left", {}, undefined, true)
    ]),
    _createVNode(_component_router_link, {
      class: "logo",
      to: "/"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ciena_logo)
      ]),
      _: 1
    }),
    (!_ctx.noSearch)
      ? (_openBlock(), _createBlock(_component_search_input_header, {
          key: 1,
          value: _ctx.inputValue,
          onHandleSubmit: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('onSearchBarSubmit', $event)))
        }, null, 8, ["value"]))
      : _createCommentVNode("", true)
  ]))
}