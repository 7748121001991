import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, withKeys as _withKeys, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "selectInputRef",
  class: "select-input-wrapper"
}
const _hoisted_2 = {
  key: 1,
  class: "select-input-drop-down-wrapper"
}
const _hoisted_3 = ["onClick", "onKeydown"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chevron_down_svg = _resolveComponent("chevron-down-svg")!
  const _component_close_svg = _resolveComponent("close-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_chevron_down_svg, {
      class: _normalizeClass({
        'select-input-chevron': true,
        'select-input-chevron-open': _ctx.isOpened,
      }),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpened = !_ctx.isOpened))
    }, null, 8, ["class"]),
    _createElementVNode("div", {
      class: "select-input-item",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openDropDown && _ctx.openDropDown(...args))),
      onKeydown: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.openDropDown && _ctx.openDropDown(...args)), ["enter"]))
    }, _toDisplayString(_ctx.getSelectValue), 33),
    (_ctx.value)
      ? (_openBlock(), _createBlock(_component_close_svg, {
          key: 0,
          class: "remove-value-icon",
          onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.selectOption('')), ["prevent"]))
        }))
      : _createCommentVNode("", true),
    (_ctx.isOpened)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
            return (_openBlock(), _createElementBlock("div", {
              key: option.value,
              class: "select-input-drop-down-item",
              onClick: ($event: any) => (_ctx.selectOption(option.value)),
              onKeydown: _withKeys(($event: any) => (_ctx.selectOption(option.value)), ["enter"])
            }, _toDisplayString(option.label), 41, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 512))
}