
import {computed, defineComponent, onMounted, ref} from 'vue';
import Pagination from '@/components/Pagination.vue';
import { IQuiz } from '@/types/quiz';
import {routeNewDocument, routeQuestion} from '@/core/constants';
import {
  apiGetQuiz,
  archiveQuizApi,
  getQuizFilters,
  GetQuizRequestFiltersPayload,
  searchQuizApi
} from '@/api/QuizApi';
import {useRoute, useRouter} from 'vue-router';
import QuizFilters from "@/components/QuizFilters.vue";
import {useStore} from 'vuex';
import {key} from '@/store';
import BaseButton from '@/components/BaseButton.vue';
import {apiCreateQuestionNew} from '@/api/QuestionApi';
import TheModal from '@/components/TheModal.vue';
import DocumentCardCheckbox from '@/components/DocumentCardCheckbox.vue';

export default defineComponent({
  name: 'QuestionSelectPartners',
  components: {
    QuizFilters,
    DocumentCardCheckbox,
    Pagination,
    BaseButton,
    TheModal,
  },
  setup() {

    const route = useRoute();
    const router = useRouter();
    const store = useStore(key);

    const questionId = parseInt(route.params.id.toString(), 10);

    const savedQuestion = computed(() => store.state.createQuestion.question);
    const savedQuizIds = computed(() => store.state.createQuestion.quizIds);
    const quizPayload = computed(() => store.state['getQuizPayload']);

    const name = ref('');
    const color = ref(1);
    const numberOfPartnersThatQuestionBeenSaved = ref<number>(0);
    const {
      params: { id },
    } = route;
    const quizId = parseInt(id.toString(), 10);
    const questionCreateConfirmIsOpen = ref(false);
    const rNewDocument = ref(routeNewDocument);
    const documents = ref<IQuiz[]>([]);
    const pageMeta = ref({
      current_page: null,
      last_page: null,
      from: null,
      to: null,
      total: null,
    });
    const businessFunctionId = ref(-1);

    onMounted(() => {
      getQuizFilters().then(res => {
        businessFunctionId.value = res['business_function'].question_template_id;
      });
      apiGetQuiz(questionId).then((res: IQuiz | any) => {
        name.value = res.name;
        color.value = res.color;
      });
    })

    const handleQuizResponse = (res: any) => {
      documents.value = res.data.data;
      pageMeta.value = res.data.meta;
    };

    const getQuizzes = (page = 1) => {
      const payload = {
        ...quizPayload.value,
        page,
      }
      store.commit('setQuizPayload', payload);
      searchQuizApi(payload).then(handleQuizResponse);
    };
    onMounted(() => {
      searchQuizApi(quizPayload.value).then(handleQuizResponse);
    });
    const toggleQuiz = (quizId: number) => {
      store.commit('quizIdsToggle', quizId);
    };
    const handleSearch = (searchInputValue: string): void => {
      const payload = {
        ...quizPayload.value,
        name: searchInputValue,
        page: 1,
      }
      store.commit('setQuizPayload', payload)

      searchQuizApi(payload).then(handleQuizResponse);
    };
    const navigateToPage = (page: number) => {
      getQuizzes(page);
    };

    const archiveQuiz = (quizId: number) => {
      archiveQuizApi(quizId).then(() => {
        getQuizzes(pageMeta.value.current_page || 1);
      });
    };

    function onFilterChange(data: GetQuizRequestFiltersPayload) {
      const payload = {
        ...quizPayload.value,
        ...data,
        page: 1,
      }
      store.commit('setQuizPayload', payload);
      searchQuizApi(payload).then(handleQuizResponse);
    }

    function save() {

      if(!savedQuestion.value) throw new Error("Question doesn't exist");
      numberOfPartnersThatQuestionBeenSaved.value = savedQuizIds.value.length;
      apiCreateQuestionNew(quizId, {question: savedQuestion.value, partner_ids: savedQuizIds.value})
        .then(() => {
          questionCreateConfirmIsOpen.value = true;
          store.commit('setCreatedQuestionInitialState');
          store.commit('setQuestionEditMode', false);
        })

    }

    function cancel() {
      store.commit('quizIdsSet', []);
      router.push({
        name: routeQuestion,
        params: {
          id: quizId,
        },
      });


      // redirect to question page
    }

    function selectAll() {
      store.commit('quizIdsAdd', documents.value.map(el => el.id));
    }
    function deselectAll() {
      store.commit('quizIdsOmit', documents.value.map(el => el.id));
    }
    return {
      documents,
      rNewDocument,
      handleSearch,
      navigateToPage,
      archiveQuiz,
      pageMeta,
      onFilterChange,
      businessFunctionId,

      numberOfPartnersThatQuestionBeenSaved,

      save,
      cancel,

      questionCreateConfirmIsOpen,
      savedQuizIds,
      toggleQuiz,

      selectAll,
      deselectAll,
    };
  },
});
