
import ChevronDownSvg from "@/assets/img/chevron-down.vue";
import {PropType, defineComponent, ref, computed} from "vue";
import { IQuestion } from '@/types/question';
import { onClickOutside } from '@vueuse/core';
import CheckedSvg from '@/assets/img/checked.vue';
import question from '@/components/Question/Question.vue';
export default defineComponent({
  name: 'SearchMultipleSelect',
  components: {
    ChevronDownSvg,
    CheckedSvg,
  },
  props: {
    question: {
      type: Object as PropType<IQuestion>,
      required: true,
    }
  },
  emits: ['onUpdate'],
  setup(props,ctx) {
    const isOpen = ref(false);
    const searchValue = ref('');
    const searchMultipleSelectWrapper = ref(null);
    // console.log(props.question);
    const selectedItems = ref(props.question.user_answer || [])
    console.log(selectedItems.value);
    onClickOutside(searchMultipleSelectWrapper, (event) => {
      isOpen.value = false;
    });




    const inputPlaceholder = computed(() => {
      let str = '';
      Object.values(selectedItems.value).map(key => {
        str += `${props.question.answer_values[key]}, `;
      })
      return  str;
    })






    const isAllSelected = computed(() => {
      const questionAnswerLength = Object.keys(props.question.answer_values).length;
      const userAnswerLength = selectedItems.value.length;
      return questionAnswerLength === userAnswerLength;
    });

    function toggleAll() {
      if(isAllSelected.value) {
        selectedItems.value = []
      } else {
        if(Array.isArray(props.question.user_answer)) {
          const payload = Object.keys(props.question.answer_values);
          // console.log({payload});
          selectedItems.value = payload;
        }

      }
    }

    const getSearchSelectInputOptions = (
      answerValues: Record<string, string>,
    ): Array<{ name: string, id: string}> => {
      const response: Array<{ name: string, id: string}> = [];
      Object.keys(answerValues).forEach((key) => {
        if(answerValues[key].indexOf(searchValue.value ) !== -1) {
          response.push({
            name: answerValues[key], id: key,
          });
        }
      });
      return response;
    };

    const arrayIncludeOrOmit = (array: Array<string>, value: string): Array<string> => {
      const ix = array.indexOf(value);
      if (ix !== -1) {
        array.splice(ix, 1);
      } else {
        array.push(value);
      }
      return array;
    };

    const onItemClick = (id: string) => {
      const payload = arrayIncludeOrOmit([...selectedItems.value], id);
      selectedItems.value = payload;
      ctx.emit('onUpdate', payload);
    }


    return {
      getSearchSelectInputOptions,
      isOpen,
      searchMultipleSelectWrapper,
      selectedItems,
      onItemClick,
      searchValue,
      isAllSelected,
      toggleAll,
      inputPlaceholder,
    }
  }
});
