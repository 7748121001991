import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "option-drop-down-row-svg-icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "option-drop-down-row",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onItemClick && _ctx.onItemClick(...args))),
    onKeydown: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onItemClick && _ctx.onItemClick(...args)), ["enter"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "icon")
    ]),
    _createElementVNode("span", null, [
      _createTextVNode(_toDisplayString(_ctx.text) + " ", 1),
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 32))
}