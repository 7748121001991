
import BaseButton from '@/components/BaseButton.vue';
import { defineComponent } from 'vue';
import { logOut } from "@/api/authApi";
import {BASE_URL} from "@/constants/app-config";

export default defineComponent({
  name: 'LogIn',
  components: { BaseButton },
  methods: {
    async logIn() {
      try {
        // const response = await logIn()
        window.location.href = (`${BASE_URL}/auth/saml/login`)
        // console.log(response);
      } catch (e) {
        console.error(e);
      }

    },
    async logout() {
      logOut();
    },
  },
});
