import req from '@/api/utilities/axios';
export interface ImportResource {
  created_at: string;
  id: number;
  processed_lines: null | number,
  state: null | string;
  total_lines: null | number;
  updated_at: string;
}

type AxiosResponse<T> = Promise<{
  data: {
    data: T
  }
}>

export const apiImport = (payload: FormData):
  AxiosResponse<ImportResource> =>
  req.post('import', payload)


export const apiGetImportStatus = (id: number):
  AxiosResponse<ImportResource> =>
  req.get(`import/${id}`);

export const apiImportAndReplace = (id: number):
  AxiosResponse<ImportResource> =>
  req.post(`import/${id}/replace`);

export const apiImportAndAdd = (id: number): AxiosResponse<ImportResource> =>
  req.post(`import/${id}/add`);

export const apiDownloadPartnerData = (
  request_id: string,
  abortControllerSignal: AbortSignal,
  partner_ids?: number[],
):
  Promise<{
      data: Blob,
      headers:{ filename: string}
    }> =>
  req.post(`export?request_id=${request_id}`,{
      partner_ids: partner_ids
  }, {
    responseType: 'blob',
    signal: abortControllerSignal,
});

export const apiCancelDownloadPartnerData = (request_id: string):
  AxiosResponse<any> =>
  req.delete(`export?request_id=${request_id}`);
