import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search-input-header-wrapper" }
const _hoisted_2 = {
  for: "search-input",
  class: "d-block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchSvg = _resolveComponent("SearchSvg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SearchSvg, {
      class: "search-input-header-svg",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('handleSubmit', _ctx.inputValue)))
    }),
    _createElementVNode("label", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        id: "search-input",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputValue) = $event)),
        placeholder: "Search documents",
        class: "search-input-header-input",
        type: "text",
        onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.$emit('handleSubmit', _ctx.inputValue)), ["enter"]))
      }, null, 544), [
        [_vModelText, _ctx.inputValue]
      ])
    ])
  ]))
}