
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'QuestionTitle',
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  emits: ['handleQuestionTitleChange'],
  setup(props, ctx) {
    const handleTitleChange = (value: string) => {
      ctx.emit('handleQuestionTitleChange', value);
    };

    function createDebounce() {
      let timeout: any = null;
      return function (fnc: () => any) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, 500);
      };
    }

    return {
      handleTitleChange,
      debounce: createDebounce(),
    };
  },
});
