
import {computed, defineComponent, ref} from 'vue';
import {useStore} from 'vuex';
import {key} from '@/store';
import ConfirmModal from '@/components/ConfirmModal.vue';
import DropDown from '@/components/DropDown.vue';
import TheModal from '@/components/TheModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import {apiCreateQuestionNew, ApiCreateQuestionNewPayload} from '@/api/QuestionApi';
import {useRouter} from 'vue-router';
import {routeQuestionSelectPartner} from '@/core/constants';

export default defineComponent({
  name: 'AddQuestionToQuizModal',
  components: {
    DropDown,
    TheModal,
    BaseButton,
  },
  props: {
    currentPartnerQuizId: {
      type: Number,
      required: true,
    }
  },
  setup(props) {
    const store = useStore(key);
    const isOpen = computed(() => store.state.createQuestion.isSaveNowQuestionLocationOpen);
    const savedQuestion = computed(() => store.state.createQuestion.question);
    const router = useRouter();

    const questionCreateConfirmIsOpen = ref(false);
    interface OptionI {
      placeholderName: string;
      name: string;
      id: number;
    }
    const CREATE_QUESTION_CURRENT_PARTNER_ID = 1;
    const CREATE_QUESTION_ALL_PARTNER_ID = 2;
    const CREATE_QUESTION_SELECT_PARTNER_ID = 3;
    const currentOption = ref<OptionI>({
      placeholderName: '1 Partner',
      name: 'Add new question 1 Partner',
      id: 1
    });
    const options = ref<Array<OptionI>>([
      {
        placeholderName: '1 Partner',
        name: 'Add new question 1 Partner',
        id: CREATE_QUESTION_CURRENT_PARTNER_ID
      },
      {
        placeholderName: 'All Partners',
        name: 'Add new question to all Partners',
        id: CREATE_QUESTION_ALL_PARTNER_ID
      },
      {
        placeholderName: 'Selected Partners',
        name: 'Add new question to selected Partners',
        id: CREATE_QUESTION_SELECT_PARTNER_ID
      },
    ]);

    const onSave = () => {
      const optionId = currentOption.value.id;

      if(optionId === CREATE_QUESTION_SELECT_PARTNER_ID) {
        // redirect to select partner page
        router.push({
          name: routeQuestionSelectPartner,
          params: {
            id: props.currentPartnerQuizId,
          },
        });

        return;
      }

      let partnerIds: Array<number> = [];
      if(optionId === CREATE_QUESTION_CURRENT_PARTNER_ID)  {
        partnerIds = [props.currentPartnerQuizId];
      }
      if(!savedQuestion.value) throw new Error("Question doesn't exist");

      const payload: ApiCreateQuestionNewPayload = {
        partner_ids: partnerIds,
        question: savedQuestion.value,
      };
      apiCreateQuestionNew(props.currentPartnerQuizId, payload)
        .then(() => {
          questionCreateConfirmIsOpen.value = true;
          store.commit('setCreatedQuestionInitialState');
          closeModal();
          store.commit('setQuestionEditMode', false);
        })
    }
    const onCancel = () => {
      closeModal();
    }

    const closeModal = () => {
      store.commit('setOpenSaveNowQuestionLocationOpen', false);
    }

    const setOption = (opt: OptionI) => {
      currentOption.value = opt;
    }
    return {
      isOpen,
      onSave,
      onCancel,
      options,
      setOption,
      currentOption,
      questionCreateConfirmIsOpen,
      CREATE_QUESTION_ALL_PARTNER_ID,
      CREATE_QUESTION_SELECT_PARTNER_ID,
      CREATE_QUESTION_CURRENT_PARTNER_ID,
    }
  }
});
