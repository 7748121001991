
import {defineComponent, PropType} from "vue";

export default defineComponent({
  name: 'CheckedSvg',
  props: {
    fillColor: {
        type: String,
        default: '#0550D1',
    }
  }
})
