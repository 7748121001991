

export default {
  name: 'MenuLink',
  props: {
    text: {
      type: String,
      default: '',
      required: true,
    },
    url: {
      type: String,
      default: '',
      required: true,
    },
  },
};
