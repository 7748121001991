import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-752cab2b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "questionTypeRef",
  class: "question-type-selector-wrapper"
}
const _hoisted_2 = { class: "question-type-selector-label p2" }
const _hoisted_3 = {
  key: 0,
  class: "question-type-selector-dropdown"
}
const _hoisted_4 = ["onClick", "onKeydown"]
const _hoisted_5 = { class: "question-type-selector-label-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronDownSvg = _resolveComponent("ChevronDownSvg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "question-type-selector-item",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openDropDownFn && _ctx.openDropDownFn(...args))),
      onKeydown: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.openDropDownFn && _ctx.openDropDownFn(...args)), ["enter"]))
    }, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1),
      _createVNode(_component_ChevronDownSvg, {
        class: _normalizeClass({
          'question-type-selector-chevron': true,
          'question-type-selector-chevron-open': _ctx.isDropDownOpen,
        })
      }, null, 8, ["class"])
    ], 32),
    (_ctx.isDropDownOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableOptions, (option) => {
            return (_openBlock(), _createElementBlock("div", {
              key: option.value,
              class: "question-type-selector-item question-type-selector-item--dropdown",
              onClick: ($event: any) => (_ctx.handleTypeChangeFn(option.value)),
              onKeydown: _withKeys(($event: any) => (_ctx.handleTypeChangeFn(option.value)), ["enter"])
            }, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(option.label), 1)
            ], 40, _hoisted_4))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 512))
}