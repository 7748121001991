import req from '@/api/utilities/axios';

export const apiGetReport = (
  year: number
): Promise<{
  data: {
    q1: QuadrantItem,
    q2: QuadrantItem,
    q3: QuadrantItem,
    q4: QuadrantItem
  }
}> => req.get(`report/${year}`).then(r => r.data.data);



export interface QuadrantItem  {
  new_partners: number,
  is_completed: 0 | 1,
  macd: {
    move: number,
    add: number,
    change: number,
    disconnect: number
  }
}
