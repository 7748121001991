import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23aac485"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "progress-circle" }
const _hoisted_2 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CircleProgress = _resolveComponent("CircleProgress")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CircleProgress, {
      percent: _ctx.progress * 100,
      viewport: true,
      "show-percent": false,
      "is-gradient": false,
      "border-width": 3,
      "border-bg-width": 0,
      "fill-color": "#22B02E",
      background: "#E1FFE4",
      size: 40
    }, null, 8, ["percent"]),
    _createElementVNode("span", _hoisted_2, _toDisplayString((_ctx.progress * 100).toFixed(0)) + "%", 1)
  ]))
}