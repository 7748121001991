
import {defineComponent, PropType, reactive, computed, ref, watch} from 'vue';
import QuestionTypeSelector from '@/components/Question/QuestionTypeSelector.vue';
import QuestionActions from '@/components/Question/QuestionActions.vue';
import QuestionTitle from '@/components/Question/QuestionTitle.vue';
import { IQuestion, questionAnswerValues, questionTypes } from '@/types/question';
import { onClickOutside } from '@vueuse/core';
import TheGradient from '@/components/TheGradient.vue';
import OptionText from '@/components/Question/options/OptionText.vue';
import OptionDate from '@/components/Question/options/OptionDate.vue';
import OptionYesNo from '@/components/Question/options/OptionYesNo.vue';
import OptionMultiple from '@/components/Question/options/OptionMultiple.vue';
import OptionDropDown from '@/components/OptionDropDown/OptionDropDown.vue';
import OptionDropDownRow from '@/components/OptionDropDown/OptionDropDownRow.vue';
import QuestionRules from '@/components/Question/QuestionRules.vue';
import DeleteSvg from '@/assets/img/delete.vue';
import PlusSvg from '@/assets/img/plus.vue';
import {useStore} from "vuex";
import {key} from "@/store";
import BaseButton from '@/components/BaseButton.vue';
import useModal from '@/hooks/useModal';
import TheModal from '@/components/TheModal.vue';

export default defineComponent({
  name: 'Question',
  components: {
    TheGradient,
    QuestionTypeSelector,
    QuestionActions,
    QuestionTitle,
    OptionText,
    OptionDate,
    OptionYesNo,
    OptionMultiple,
    OptionDropDown,
    OptionDropDownRow,
    DeleteSvg,
    PlusSvg,
    QuestionRules,
    BaseButton,
    TheModal
  },
  props: {
    question: {
      type: Object as PropType<IQuestion>,
      required: true,
    },
    ix: {
      type: Number,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    isEditingRules: {
      type: Boolean,
      required: true,
    },
    allQuestions: {
      type: Object as PropType<IQuestion[]> | null,
      require: true,
    },
  },
  emits: [
    'handleTypeChange',
    'handleClick',
    'handleAddQuestion',
    'handleQuestionTitleChange',
    'updateAnswerOptions',
    'removeQuestion',
    'handleAddRule',
    'handleCancelRule',
    'handleSaveRule',
    'removeAnswerOptionRule',
    'onCancelAddedQuestion',
    'onSaveAddedQuestion',
    'onCancelEditedQuestion',
    'onSaveEditedQuestion',
  ],
  setup(props, ctx) {
    const store = useStore(key);
    const currentCategory = computed(() => store.state.currentCategory);
    const createQuestion = computed(() => store.state.createQuestion);
    const questionReference = ref(null);
    const handleTypeChange = (val: questionTypes) => {
      ctx.emit('handleTypeChange', props.ix, val);
    };
    const initialQuestion = ref<null | IQuestion>(null)

    const preventAnswerModeModal = useModal(false);

    const questionOptionsComponentAliases = reactive({
      text: 'OptionText',
      drop_down: 'OptionMultiple',
      yes_no: 'OptionYesNo',
      multiple: 'OptionMultiple',
      date: 'OptionDate',
    });
    onClickOutside(questionReference, () => {
      if (props.isActive) {
        ctx.emit('handleClick', null);
      }
    });


    const handleQuestionTitleChange = (val: string) => {
      ctx.emit('handleQuestionTitleChange', props.ix, val);
    };

    const handleAddQuestion = () => ctx.emit('handleAddQuestion', props.ix, props.question.position + 1);

    const handleClick = (questionId: number) => ctx.emit('handleClick', questionId);

    const updateAnswerOptions = (ix: number, answerValues: questionAnswerValues) => {
      ctx.emit('updateAnswerOptions', ix, answerValues);
    };

    const removeQuestion = () => {
      ctx.emit('removeQuestion', props.ix);
    };

    const handleAddRule = () => ctx.emit('handleAddRule', props.ix);

    const handleRuleSave = (rules: any) => {
      ctx.emit('handleSaveRule', props.ix, rules);
    };

    const handleCancelRule = () => ctx.emit('handleCancelRule', props.ix);
    const doesQuestionHaveRule = computed(() => props.question.rules.length > 0);
    const removeAnswerOptionRule = (uuid: string) => {
      ctx.emit('removeAnswerOptionRule', props.ix, uuid);
    };

    const setAnswerMode = () => {
      if(createQuestion.value.isCreateQuestionInitiated) {
        preventAnswerModeModal.open();
        return;
      }
      store.commit('setQuestionEditMode', false);
    }
    const handleCancelEditQuestion = (e: any) => {
      e.stopPropagation();
      // should be set default question
      ctx.emit('onCancelEditedQuestion', initialQuestion.value);
    }
    const onEditClick = () => {
      initialQuestion.value = JSON.parse(JSON.stringify(props.question));
      ctx.emit('handleClick', props.question.id);
    }

    const handleUpdateQuestion = () => {
      ctx.emit('onSaveEditedQuestion', props.question);
    }

    return {
      handleTypeChange,
      handleClick,
      handleAddQuestion,
      handleQuestionTitleChange,
      questionOptionsComponentAliases,
      updateAnswerOptions,
      removeQuestion,
      handleAddRule,
      handleRuleSave,
      handleCancelRule,
      doesQuestionHaveRule,
      removeAnswerOptionRule,
      questionReference,
      currentCategory: currentCategory,
      setAnswerMode,
      preventAnswerModeModal,
      handleCancelEditQuestion,
      onEditClick,
      handleUpdateQuestion,
    };
  },
});
