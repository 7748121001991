
import {defineComponent, ref} from 'vue';
import {format, parse} from 'date-fns';
export default defineComponent({
  name: 'QuestionDateInput',
  components: {

  },
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onValueChange'],
  setup(props, ctx) {
    let [month, day, year] = props.value.split('-');

    const date = ref<Date | string>(new Date(+year, +month - 1, +day));



    function onFilterByDates(inputValue: Date) {
      date.value  = inputValue;
      ctx.emit('onValueChange', format(new Date(inputValue), "MM-dd-yy"));
    }

    function cleared() {
      date.value = ''
      ctx.emit('onValueChange', '');
    }
    return {
      onFilterByDates,
      date,
      cleared,
    };
  },
});
