import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fe9628d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "checkbox-place" }
const _hoisted_2 = {
  key: 1,
  class: "checkbox-border"
}
const _hoisted_3 = { class: "document-card-name heading-5 black mb-8" }
const _hoisted_4 = { class: "d-flex" }
const _hoisted_5 = {
  key: 0,
  class: "document-card-business-function"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_gradient = _resolveComponent("the-gradient")!
  const _component_checked_svg = _resolveComponent("checked-svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      'document-card': true,
    }),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onCheck', _ctx.id)))
  }, [
    _createVNode(_component_the_gradient, { "gradient-type": _ctx.gradientType }, null, 8, ["gradient-type"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isSelected)
        ? (_openBlock(), _createBlock(_component_checked_svg, {
            key: 0,
            "fill-color": _ctx.fillColor
          }, null, 8, ["fill-color"]))
        : (_openBlock(), _createElementBlock("span", _hoisted_2))
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.name), 1),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.getBusinessFunction(_ctx.filterValues))
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.getBusinessFunction(_ctx.filterValues)), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}