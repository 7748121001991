
import {computed, defineComponent, ref, watch} from 'vue';
import SearchSvg from '@/assets/img/search.vue';
import { createDebounce } from '@/core/utils';
import {useStore} from 'vuex';
import {key} from '@/store';

export default defineComponent({
  name: 'SearchInputHeader',
  components: {
    SearchSvg,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  emits: ['handleSubmit'],
  setup(props, ctx) {
    const store = useStore(key);
    const dashboardFilters = computed(() => store.state['getQuizPayload'])
    const inputValue = ref<string>(dashboardFilters.value.name);

    const debaunceFn = createDebounce();

    watch(inputValue, () => {
      debaunceFn(() => {
        ctx.emit('handleSubmit', inputValue.value);
      });
    });

    return {
      inputValue,
    };
  },
  watch: {
    value: {
      handler(value: string): void {
        this.inputValue = value;
      },
      deep: true,
    },
  },
});
