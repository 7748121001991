import req from '@/api/utilities/axios';
import {AxiosResponse} from 'axios';

export const getQuizFilesApi = (quizId: number): Promise<{data: AxiosResponse<QuizFileAttachment[]>}> => req.get(`quiz/${quizId}/file`);
export const addQuizFilesApi = (quizId: number, file: Blob, uploadEv: (ev: any) => any) => req.post(`quiz/${quizId}/file`, (() => {
  const fd = new FormData();
  fd.append('file', file);
  return fd;
})(), {
  onUploadProgress: uploadEv,
});

export const apiDownloadQuizAttachment = async (quizId: number, fileId: number) => {
  const res = await req.get(`quiz/${quizId}/file/${fileId}`, {
    responseType: 'blob'
  });
  return res;

};

export const deleteQuizFilesApi = (quizId: number, fileId: number) => req.delete(`quiz/${quizId}/file/${fileId}`);



export interface QuizFileAttachment {
  company_name: string;
  created_at: string;
  id: number;
  name: string;
  question_template_id: number;
  quiz_id: number;
  updated_at: string;
}
