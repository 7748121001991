import {FilterValues} from '@/types/quiz';

export function getBusinessFunction(filteredValues: FilterValues) {
  let str = ''
  Object.keys(filteredValues).forEach(firstKey => {
    Object.keys(filteredValues[firstKey]).forEach(nextKey => {
      str =  filteredValues[firstKey][nextKey]
    })
  })
  return str
}
