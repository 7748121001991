import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aefa5a96"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "searchMultipleSelectWrapper",
  class: "dropdown"
}
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = { class: "dropdown-content" }
const _hoisted_4 = ["onMousedown"]
const _hoisted_5 = { class: "checkbox-place" }
const _hoisted_6 = {
  key: 1,
  class: "checkbox-border"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chevron_down_svg = _resolveComponent("chevron-down-svg")!
  const _component_checked_svg = _resolveComponent("checked-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_chevron_down_svg, {
      class: _normalizeClass({
        'select-input-chevron': true,
        'select-input-chevron-open': _ctx.isOpen,
      })
    }, null, 8, ["class"]),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event)),
      class: "dropdown-input",
      placeholder: _ctx.inputPlaceholder,
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isOpen = !_ctx.isOpen))
    }, null, 8, _hoisted_2), [
      [_vModelText, _ctx.searchValue]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "dropdown-item",
          onMousedown: ($event: any) => (_ctx.onItemClick(option))
        }, [
          _createElementVNode("div", _hoisted_5, [
            (_ctx.isItemSelected(option))
              ? (_openBlock(), _createBlock(_component_checked_svg, { key: 0 }))
              : (_openBlock(), _createElementBlock("span", _hoisted_6))
          ]),
          _createTextVNode(" " + _toDisplayString(option.label), 1)
        ], 40, _hoisted_4))
      }), 128))
    ], 512), [
      [_vShow, _ctx.isOpen]
    ])
  ], 512))
}