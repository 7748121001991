
import {routeDashboard, routeLogIn} from '@/core/constants';
import { defineComponent } from 'vue';
import {useRoute, useRouter} from 'vue-router';
import { authCheck } from "@/api/authApi";
import { useStore } from 'vuex'
import axiosInstance from '@/api/utilities/axios';
import {key} from "@/store";

export default defineComponent({
  name: 'LoginCallbackPage',
  components: {
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore(key);
    const {
      params: { code },
    } = route;
    authCheck({ code: code as string })
      .then(res => {
        const token = res.data['access-token'];
        sessionStorage.setItem('token', token);
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
        store.commit('setIsAuthenticated', true)
        router.push({ name: routeDashboard });

      }).catch(e => {
        console.log(e)
        store.commit('setIsAuthenticated', false);
        router.push({ name: routeLogIn });
    });
  },
});
