import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b8159b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "percent-box" }
const _hoisted_2 = { calss: "state" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_circle_progress = _resolveComponent("circle-progress")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_circle_progress, {
      percent: _ctx.percentProgress,
      size: 60,
      "border-width": 5,
      "border-bg-width": 5,
      "show-percent": true,
      unit: '%'
    }, null, 8, ["percent"]),
    _createElementVNode("p", _hoisted_2, " State: " + _toDisplayString(_ctx.state), 1)
  ]))
}