
import PrevSvg from '@/assets/img/arrow-back.vue';
import ForwardSvg from '@/assets/img/arrow-forward.vue';
import { defineComponent, onMounted, PropType, ref, watch } from 'vue';

export type linkInterface = Array<{ url: null | string; lable: string; active: boolean }>;

export interface metaInterface {
  links: linkInterface[];
  current_page: number;
  from: number;
  last_page: number;
  path: string;
  per_page: number;
  to: number;
  total: number;
}

export default defineComponent({
  name: 'Pagination',
  components: {
    PrevSvg,
    ForwardSvg,
  },
  props: {
    totalPages: {
      type: Number,
      default: 12,
      required: true,
    },
    currentPage: {
      type: Number,
      default: 12,
      required: true,
    },
    meta: {
      type: Object as PropType<metaInterface>,
      required: true,
    },
    label: {
      type: String,
      default: 'documents'
    }
  },
  emits: ['handleClick'],
  setup(props, context: any): any {
    const pages = ref<Array<linkInterface>>([]);
    const createPagination = () => {
      pages.value = [];
      for (let i = 1; i < props.meta.links.length - 1; i += 1) {
        pages.value.push(props.meta.links[i]);
      }
    };
    onMounted(() => {
      createPagination();
    });
    watch(
      () => props.meta,
      () => {
        createPagination();
      }
    );

    const handleClick = (page: number) => {
      context.emit('handleClick', page);
    };

    const toNextPage = () => {
      const nextPage = props.meta.current_page + 1;
      if(nextPage <= props.meta.last_page )  {
        handleClick(nextPage);
      }
    }
    const toPrevPage = () => {
      const nextPage = props.meta.current_page - 1;
      if(nextPage >= 1 )  {
        handleClick(nextPage);
      }
    }

    return {
      handleClick,
      pages,
      toPrevPage,
      toNextPage
    };
  },
});
