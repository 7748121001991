
import axiosInstance from "@/api/utilities/axios";
import {defineComponent, ref} from "vue";
import {useStore} from "vuex";
import {key} from "@/store";
import TheModal from '@/components/TheModal.vue';
import BaseButton from '@/components/BaseButton.vue';

const UPDATE_MODAL_TOKEN = 'update_1';

export default defineComponent({
  name: "App",
  components: {BaseButton, TheModal},
  setup() {
    localStorage.removeItem('token');


    const store = useStore(key);
    const token = sessionStorage.getItem('token');
    if(!(token == null)) {
      store.commit('setIsAuthenticated', true)
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
    }


    const shouldShowUpdateModal = ref(false);

    const onConfirmUpdateModal = () => {
      shouldShowUpdateModal.value = false;
      localStorage.setItem(UPDATE_MODAL_TOKEN, 'yes');
    }

    return {
      shouldShowUpdateModal,
      onConfirmUpdateModal
    }
  }
})
