// Route names
export const routeLogIn = 'login';
export const routeDashboard = 'dashboard';
export const routeTemplate = 'template';
export const routeArchive = 'archive';
export const routeNewDocument = 'new-document';
export const routeEditQuestion = 'edit-question';
export const routeAnswerQuestion = 'answer-question';
export const routeQuestion = 'question';
export const routeQuestionSelectPartner = 'question-select-partner'
export const routeQuestionDeleteSelectPartner = 'question-select-delete-partner'
export const routeQuestionEditSelectPartner = 'question-select-edit-partner'
export const routeBulkDownloadSelectedPartners = 'bulk-download-selected-partners';
export const routeReport = 'report';

export const routeUpload = 'upload';
export const routeMaintenance = 'maintenance'
export const routeReleaseNotes = 'release-notes'

export const ACTIVITY_ID = -2;
export const ATTACHMENTS_ID = -3;
