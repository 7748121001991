import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_svg = _resolveComponent("close-svg")!
  const _component_question_option_input = _resolveComponent("question-option-input")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.answer_values, (value, key, ix) => {
      return (_openBlock(), _createElementBlock("div", {
        key: key,
        class: "position-relative"
      }, [
        (ix > 0)
          ? (_openBlock(), _createBlock(_component_close_svg, {
              key: 0,
              class: "remove-question-option",
              onClick: _withModifiers(($event: any) => (_ctx.removeAnswerValue(key)), ["stop"]),
              onKeydown: _withKeys(_withModifiers(($event: any) => (_ctx.removeAnswerValue(key)), ["stop"]), ["enter"])
            }, null, 8, ["onClick", "onKeydown"]))
          : _createCommentVNode("", true),
        _createVNode(_component_question_option_input, {
          class: "mt-10",
          value: value,
          placeholder: "Type your option..",
          onOnValueChange: ($event: any) => (_ctx.handleValueChange(key, $event))
        }, null, 8, ["value", "onOnValueChange"])
      ]))
    }), 128)),
    _createElementVNode("div", {
      class: "option-multiple-add-option",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addNewAnswerValue && _ctx.addNewAnswerValue(...args))),
      onKeydown: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.addNewAnswerValue && _ctx.addNewAnswerValue(...args)), ["enter"]))
    }, " Add option + ", 32)
  ], 64))
}