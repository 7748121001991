import req from '@/api/utilities/axios';
import ApiCore from '@/api/utilities/apiCore';
import { AxiosResponse } from 'axios';
import { ApiQuizResponse, QuizRequestPayload, QuizResponsePayload } from '@/types/quiz';
import {QuadrantItem} from "@/api/reportApi";
import {linkInterface, metaInterface} from '@/components/Pagination.vue';
import {format} from 'date-fns';
import {User} from '@/User';
import type = Mocha.utils.type;

const url = 'quiz';

// plural and single may be used for message logic if needed in the ApiCore class.
const apiQuiz = new ApiCore({
  getAll: true,
  getSingle: true,
  post: true,
  update: true,
  patch: true,
  delete: true,
  url,
});

export const apiGetQuiz = async (quizId: number): Promise<QuizResponsePayload> => {
  const payload = (await req.get(`quiz/${quizId}`)).data.data;
  return payload;
};
// export const apiShowQuizAnswer = (quizId: number) => req.get(`quiz/${quizId}/answer`);
export const apiAnswerQuiz = (
  quizId: number,
  answer: { [key:number]: string | Array<string> },
): Promise<AxiosResponse<any>> => {
  const payload = {
    quiz_id: quizId,
    answer,
  };
  return req.post(`quiz/${quizId}/answer`, payload);
};

export const createQuiz = (
  payload: QuizRequestPayload
): ApiQuizResponse => req.post('quiz', payload);

export const getAllQuiz = (page = 1): Promise<AxiosResponse<any>> => req.get(`quiz?page=${page}`);


export interface GetQuizRequestFiltersPayload  {
  sort_by: 'created_at' | 'name' | 'updated_at',
  sort_dir: 'desc' | 'asc',
  filter?: {
    question_template_ids?: Record<string | number, Array<string>>,
    question_id_name?: number | null,
    question_ids: Record<string | number, Array<string>>
  }
}

export interface GetQuizRequestPayload extends GetQuizRequestFiltersPayload  {
  page: number,
  name: string,
  show_only_archived?: boolean,
}
export const searchQuizApi = (data: GetQuizRequestPayload): Promise<AxiosResponse<any>> => {
  const {page, ...payload} = data
  return req.post(`quiz/search?page=${page}`, payload);
};

export const archiveQuizApi = (quizId: number): Promise<AxiosResponse<any>> => req.put(`quiz/${quizId}/archive`);

export const unArchiveQuizApi = (quizId: number): Promise<AxiosResponse<any>> => req.put(`quiz/${quizId}/unarchive`);

export const getArchivedQuizApi = (page = 1): Promise<AxiosResponse<any>> => req.get('quiz/archived');

export const getQuizFilters = (): Promise<Record<'business_function',FilterItem> & Macd> => req.get('filter').then(r => r.data.data);

export const apiGetQuizActivityLogs = (quizId: number, page: number, from: Date | null, to: Date | null, userId?:string, questionId?:string): Promise<AxiosResponse<GetQuizActivityLogsResponse>> => {
  const payload: GetQuizActivityLogRequest = {
    page,
    date_from: from ? format(from, 'YYY-MM-dd') : undefined,
    date_to: to ? format(to, 'YYY-MM-dd'): undefined,
    user_ids:  userId ?  [parseInt(userId, 10)] : undefined,
    question_ids: questionId ? [parseInt(questionId, 10)] : undefined ,
  };

  return req.post(`quiz/${quizId}/activity-logs`, payload)
};

export const getUserList = (): Promise<AxiosResponse<{data: User[]}>> => req.get('user');

export interface SidebarFilters {
  id: number;
  text: string;
  answer_values: Record<string, string>;
  type: 'drop_down' | 'multiple'
}
export const getSidebarFilters = (): Promise<Array<SidebarFilters>> => req.get('filter/questions').then(r => r.data.data);

export default apiQuiz;

export interface FilterItem {
  id: number,
  question_template_id: number,
  text: string,
  type: string,
  answer_values: Record<string, string>,
}

type Macd  = {
  macd?: FilterItem
};


export interface LogItem {
  id: number,
  causer_id: null,
  causer_type: null,
  created_at: string,
  updated_at: string;
  description: string,
  event: string,
  subject_id: number;
  subject_type: string,
  user: {
    id: null,
    name: null,
  },
  properties: {
    attributes: Record<number, any>
    old: Record<number, any>
  },
  question: {
    text: string;
    id: number;
  };
}

interface GetQuizActivityLogsResponse {
  meta: metaInterface;
  links: linkInterface;
  data: Array<LogItem>
}



type GetQuizActivityLogRequest = {
  page: number,
  date_from: string | undefined,
  date_to: string | undefined,
  user_ids: Array<number> | undefined,
  question_ids: Array<number> | undefined,
}
