import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d0a3f31"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "wrapper"
}
const _hoisted_2 = { class: "modal-box" }
const _hoisted_3 = { class: "content-box" }
const _hoisted_4 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              class: "btn btn-warning",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onConfirm')))
            }, "Confirm"),
            _createElementVNode("button", {
              class: "btn btn-default mr-10",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onCancel')))
            }, "Cancel")
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}