export const demo = 'demo';

export function createDebounce(ms = 1000) {
  let timeout: any = null;
  return function (fnc: () => any) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fnc();
    }, ms);
  };
}
