
import {defineComponent, ref} from "vue";
import ChevronDownSvg from "@/assets/img/chevron-down.vue";
import {onClickOutside} from "@vueuse/core";

export default defineComponent({
  name: 'DropDown',
  components: {ChevronDownSvg},
  props: {
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    isDown: {
      type: Boolean,
      default: false,
    }
  },
  setup(props){
    const isOpen = ref(props.isDown);
    const wrapper = ref(null);

    function close() {
      isOpen.value = false
    }
    function open() {
      isOpen.value = true;
    }
    onClickOutside(wrapper, (event) => {
      close();
    });

    function handleCloseOnSelect() {
      if(props.closeOnSelect) {
        close();
      }
    }

    function toggle() {
      isOpen.value = !isOpen.value
    }
    return {
      wrapper,
      isOpen,
      open,
      toggle,
      close,
      handleCloseOnSelect
    }
  }
})
