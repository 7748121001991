import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_title_text = _resolveComponent("title-text")!
  const _component_gray_text = _resolveComponent("gray-text")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_the_modal = _resolveComponent("the-modal")!

  return (_openBlock(), _createBlock(_component_the_modal, { "is-visible": _ctx.isOpen }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_title_text, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.title), 1)
          ]),
          _: 1
        }),
        (_ctx.subtitle)
          ? (_openBlock(), _createBlock(_component_gray_text, { key: 0 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.subtitle), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.deleteValue) = $event)),
          class: "input-confirm-delete",
          type: "text",
          placeholder: "DELETE"
        }, null, 512), [
          [_vModelText, _ctx.deleteValue]
        ])
      ])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_base_button, {
        class: "mx-1",
        variant: "red",
        disabled: _ctx.confirmDeleteButtonDisabled,
        onClick: _ctx.handleConfirmClick
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.confirmButtonText), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"]),
      _createVNode(_component_base_button, {
        class: "mx-1",
        variant: "outlined",
        disabled: true,
        onClick: _ctx.handleCancelClick
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.cancelButtonText), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["is-visible"]))
}