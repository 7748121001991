
import TypeTextSvg from '@/assets/img/type-text.vue';
import ChevronDownSvg from '@/assets/img/chevron-down.vue';
import OptionDropDownRow from "@/components/OptionDropDown/OptionDropDownRow.vue";
import {computed, ref} from "vue";
import {onClickOutside} from "@vueuse/core";
export default {
  name: 'UploadTypeDropDown',
  components: {
    TypeTextSvg,
    ChevronDownSvg,
    OptionDropDownRow
  },
  props: {
    type: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      required: true,
    },
    havePermissionToDownload: {
      type: Boolean,
      required: true,
    }
  },
  emits: ['onTypeChange'],
  setup() {
    const isOpen = ref(false);
    const wrapper = ref(null);

    onClickOutside(wrapper, (event) => {
      isOpen.value = false;
    });
    return {
      isOpen,
      wrapper,
    }
  },
};
