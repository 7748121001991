import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pagination" }
const _hoisted_2 = { class: "pagination-info f-inter grey-1" }
const _hoisted_3 = { class: "pagination-actions" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrevSvg = _resolveComponent("PrevSvg")!
  const _component_ForwardSvg = _resolveComponent("ForwardSvg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(" Showing "),
      _createElementVNode("b", null, _toDisplayString(_ctx.meta.from), 1),
      _createTextVNode(" to "),
      _createElementVNode("b", null, _toDisplayString(_ctx.meta.to), 1),
      _createTextVNode(" of "),
      _createElementVNode("b", null, _toDisplayString(_ctx.meta.total), 1),
      _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        class: _normalizeClass({
          'pagination-button': true,
          'pagination-button-prev': true,
          'pagination-button-disabled': _ctx.meta.current_page === 1,
        }),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toPrevPage && _ctx.toPrevPage(...args)))
      }, [
        _createVNode(_component_PrevSvg)
      ], 2),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (pageItem, ix) => {
        return (_openBlock(), _createElementBlock("button", {
          key: pageItem.page,
          class: _normalizeClass({
          'pagination-button': true,
          'pagination-button-active': pageItem.active,
          'pagination-button-disabled': _ctx.meta.current_page === ix + 1,
        }),
          onClick: ($event: any) => (_ctx.handleClick(pageItem.label))
        }, _toDisplayString(pageItem.label), 11, _hoisted_4))
      }), 128)),
      _createElementVNode("button", {
        class: _normalizeClass({
          'pagination-button': true,
          'pagination-button-next': true,
          'pagination-button-disabled': _ctx.meta.current_page === _ctx.meta.last_page,
        }),
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toNextPage && _ctx.toNextPage(...args)))
      }, [
        _createVNode(_component_ForwardSvg)
      ], 2)
    ])
  ]))
}