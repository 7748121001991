
import {computed, defineComponent, onMounted, ref} from "vue";
import DropDown from "@/components/DropDown.vue";
import {FilterItem, getQuizFilters, GetQuizRequestFiltersPayload} from "@/api/QuizApi";
import {useStore} from 'vuex';
import {key} from '@/store';

export default defineComponent({
  name: 'QuizFilters',
  components: { DropDown },
  emits: ['onFilterChange'],
  setup(_, ctx) {
    const store = useStore(key);
    const store_sortBy = computed(() => store.state.getQuizPayload.sort_by);
    const store_sortDir = computed(() => store.state.getQuizPayload.sort_dir);
    const store_filter = computed(() => store.state.getQuizPayload.filter);

    const sortBy = ref(store_sortBy.value);
    const sortDirection = ref(store_sortDir.value);

    const macd = ref<FilterItem | Record<any, any>>({});
    const macdId = ref(-1);
    const selectedMacd = ref<string | null>(null);

    const businessFunction = ref<FilterItem | Record<any, any>>({});
    const businessFunctionId = ref<number>(-1)
    const selectedBusinessFunction = ref<Array<string>>([]);

    onMounted(() => {
      getQuizFilters().then(res => {
        businessFunction.value = res['business_function'];
        businessFunctionId.value = res['business_function'].question_template_id;
        if(store_filter.value?.hasOwnProperty('question_template_ids') && store_filter.value['question_template_ids']?.hasOwnProperty(res['business_function'].question_template_id)) {
          selectedBusinessFunction.value = store_filter.value['question_template_ids'][res['business_function'].question_template_id];
        }
        if(res.macd) {
          macd.value = res.macd
          macdId.value = res.macd?.question_template_id
        }
      })
    });

    const isMacdEnabled = computed(() => {
      return macdId.value !== -1;
    })

    const sortLabel = computed(() => {
      if(sortBy.value === 'created_at') {
        if(sortDirection.value === 'desc') {
          return 'Newest';
        } else {
          return 'Oldest'
        }
      } else if(sortBy.value === 'name') {
        return 'Alphabetical'
      } else {
        return 'Last Edited'
      }
    });


    function setSort(field: GetQuizRequestFiltersPayload['sort_by'], direction: GetQuizRequestFiltersPayload['sort_dir']) {
      sortBy.value = field;
      sortDirection.value = direction;
      fireChangeEvent();
    }


    function setBusinessFunction(payload: string) {
      const elPosition = selectedBusinessFunction.value.indexOf(payload);
      const elExist = elPosition !== -1;
      if(elExist) {
        selectedBusinessFunction.value.splice(elPosition, 1);
      } else {
        selectedBusinessFunction.value.push(payload);
      }
      fireChangeEvent();
    }

    function setMacd(payload: string) {
      selectedMacd.value = payload;
      fireChangeEvent();
    }

    function fireChangeEvent() {
      const payload = {
        sort_by: sortBy.value,
        sort_dir: sortDirection.value,
        filter: {
          question_template_ids: {
            ...(selectedBusinessFunction.value.length === 0 ? {} : {[String(businessFunctionId.value)]: selectedBusinessFunction.value}),
            ...(selectedMacd.value === null ? {} : {[String(macdId.value)]: selectedMacd.value})
          }
        }
      };
      ctx.emit('onFilterChange', payload);
    }
    return {
      setSort,
      sortLabel,
      businessFunction,
      setBusinessFunction,
      selectedBusinessFunction,
      setMacd,
      macd,
      selectedMacd,
      isMacdEnabled,
    }
  }
})
