import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14812707"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  for: "question-title",
  class: "w-100"
}
const _hoisted_2 = ["value"]
const _hoisted_3 = {
  key: 1,
  class: "question-title-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isActive)
    ? (_openBlock(), _createElementBlock("label", _hoisted_1, [
        _createElementVNode("input", {
          id: "question-title",
          class: "question-title-input",
          type: "text",
          value: _ctx.value,
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleTitleChange($event.target.value)))
        }, null, 40, _hoisted_2)
      ]))
    : (_openBlock(), _createElementBlock("h4", _hoisted_3, _toDisplayString(_ctx.value), 1))
}