
import {computed, defineComponent, onMounted, ref, watch} from 'vue';
import { routeDashboard } from '@/core/constants';
import { IQuiz } from '@/types/quiz';
// import GradientPicker from '@/components/GradientPicker.vue';
import  { apiGetQuiz } from '@/api/QuizApi';
import {useRoute, useRouter} from 'vue-router';
import BackSvg from '../assets/img/arrow-back.vue';
import {key} from "@/store";
import {useStore} from "vuex";
import BaseButton from '@/components/BaseButton.vue';
import TheModal from '@/components/TheModal.vue';
import useModal from '@/hooks/useModal';
export default defineComponent({
  name: 'QuestionHeader',
  components: {
    BackSvg,
    BaseButton,
    TheModal,
  },
  props: {
  },
  setup(props) {
    const store = useStore(key);
    const isQuestionEditMode = computed(() => store.state.isQuestionsEditMode);

    const rDashboard = ref(routeDashboard);
    const route = useRoute();
    const router = useRouter();
    const questionId = parseInt(route.params.id.toString(), 10);
    const name = ref('');
    const color = ref(1);

    const createQuestion = computed(() => store.state.createQuestion);
    const preventAnswerModeModal = useModal(false);

    onMounted(() => {
      apiGetQuiz(questionId).then((res: IQuiz | any) => {
        name.value = res.name;
        color.value = res.color;
      });
    });

    const setAnswerMode = () => {
      if(createQuestion.value.isCreateQuestionInitiated) {
        preventAnswerModeModal.open();
        return;
      }
      store.commit('setQuestionEditMode', false);
    }
    // const changeQuestionColor = (selectedColor: number) => {
    //   apiQuiz.update(questionId, {
    //     color: selectedColor,
    //   }).then((res) => {
    //     color.value = res.color;
    //   });
    // };

    function goBack() {
      setAnswerMode();
      router.push({ name: rDashboard.value });
    }
    return {
      // changeQuestionColor,
      rDashboard,
      name,
      color,
      questionId,
      isQuestionEditMode,
      setAnswerMode,
      goBack,
      preventAnswerModeModal,
    };
  },
});
