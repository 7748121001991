
import { computed, defineComponent, PropType, ref } from 'vue';
import AddRuleSvg from '@/assets/img/add-rule.vue';
import PlusSvg from '@/assets/img/plus.vue';
import { questionTypes } from '@/types/question';
import {useStore} from 'vuex';
import {key} from '@/store';
import BaseButton from '@/components/BaseButton.vue';

export default defineComponent({
  name: 'QuestionActions',
  components: {
    BaseButton
  },
  emits: ['handleAddQuestion', 'handleAddRule'],
  props: {
    questionType: {
      type: String as PropType<questionTypes>,
      required: true,
    },
  },
  setup(props, ctx) {
    const store = useStore(key);
    const state = ref('');
    const handleAddQuestion = () => ctx.emit('handleAddQuestion');
    const handleAddRule = () => ctx.emit('handleAddRule');

    const haveNewQuestionCreationInitiated = computed(() => {
      return store.state.createQuestion.isCreateQuestionInitiated;
    });


    const isAddRuleAvailable = computed(() => ['drop_down', 'yes_no', 'multiple'].includes(props.questionType));
    return {
      state,
      handleAddQuestion,
      isAddRuleAvailable,
      handleAddRule,
      haveNewQuestionCreationInitiated,
    };
  },
});
