import { createApp } from 'vue';
import CienaLayout from '@/components/CienaLayout.vue';
import Notifications from '@kyvg/vue3-notification';
import App from './App.vue';
import router from './router';
import store, {key} from './store';
import Echo from 'laravel-echo';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
declare const window: Window &
  typeof globalThis & {
  Pusher: any;
  Echo: { chanel: any};
};

window.Pusher= require('pusher-js');
export let echo: Echo | undefined = undefined;


export const bootstrapSocketListeners = () => {
  echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    wsHost: window.location.hostname,
    wsPort: process.env.VUE_APP_PUSHER_APP_PORT,
    forceTLS: false,
    disableStats: true,
  });
};
bootstrapSocketListeners();
createApp(App)
  .use(store, key)
  .use(router)
  .use(Notifications)
  .component('ciena-layout', CienaLayout)
  .component('VueDatePicker', VueDatePicker)
  .mount('#app');

