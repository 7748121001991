
import { defineComponent, ref} from 'vue';
import MenuLink from '@/components/MenuLink.vue';

import NewDocumentSvg from '@/assets/img/new-document.vue';
// import TemplateSvg from '@/assets/img/template.vue';
import ArchiveSvg from '@/assets/img/archive.vue';
import ArrowBack from '@/assets/img/arrow-back.vue';
import UploadSvg from '@/assets/img/upload.vue';
import ReleaseNotesSvg from '@/assets/img/release-notes.vue';
import store, {key} from "@/store";
import { logOut } from "@/api/authApi";
import {IS_LOGIN_ENABLED} from "@/constants/app-config";
import ReportSvg from "@/assets/img/reportSvg.vue";
import {useStore} from 'vuex';

export default defineComponent({
  components: {
    ReportSvg,
    MenuLink,
    NewDocumentSvg,
    // TemplateSvg,
    ArchiveSvg,
    ArrowBack,
    UploadSvg,
    ReleaseNotesSvg
  },
  props: {
    isOpened: {
      type: Boolean,
      required: true,
    },
  },
  setup(){
    const store = useStore(key);
    const isLogInEnabled = ref(IS_LOGIN_ENABLED)

    const resetSearchValue = () => {
      store.commit('setRefetchWithoutName');
    }
    return {
      isLogInEnabled,
      resetSearchValue,
    }
  },
  emits: ['onCloseSidebar'],
  methods: {
    async logout() {
      logOut().then(() => {
        sessionStorage.removeItem('token');
        store.commit('setIsAuthenticated', false);
        location.reload()
      })
    },
  },
});
