
import {defineComponent, computed, PropType, ref, onMounted} from 'vue';
import documentGradients from '@/constants/documentGradients';
import OptionDropDown from '@/components/OptionDropDown/OptionDropDown.vue';
import OptionDropDownRow from '@/components/OptionDropDown/OptionDropDownRow.vue';
import TheGradient from '@/components/TheGradient.vue';
import DeleteSvg from '@/assets/img/delete.vue';
import ProgressBar from "@/components/ProgressBar.vue";
import { format } from 'date-fns';
import {FilterValues} from "@/types/quiz";
import {getQuizFilters} from "@/api/QuizApi";
import {getBusinessFunction} from '@/utils/utils';

export default defineComponent({
  name: 'ArchiveCard',
  methods: {getBusinessFunction},
  components: {
    TheGradient,
    OptionDropDown,
    OptionDropDownRow,
    DeleteSvg,
    ProgressBar,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    lastEdited: {
      type: String,
      required: true,
    },
    progress: {
      type: Number,
      required: true,
    },
    gradientType: {
      type: Number,
      required: true,
      validator(val: number) {
        return Object.keys(documentGradients).includes(val.toString(10));
      },
    },
    size: {
      type: String,
      default: 'medium',
      validator(value: string) {
        return ['small', 'medium'].includes(value);
      },
    },
    filterValues: {
      type: Object as PropType<FilterValues>,
      required: true,
    },
    businessFunctionId: {
      type: Number,
      required: true,
    },
  },
  emits: ['onUnarchive'],
  setup(props: { size: string; id: number, businessFunctionId: number }, ctx) {
    const isCardTypeSmall = computed(() => props.size === 'small');
    const unarchiveCard = () => {
      ctx.emit('onUnarchive', props.id);
    };

    return {
      isCardTypeSmall,
      unarchiveCard,
      format,
    };
  },
});
