import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "wrapper",
  class: "drop-down"
}
const _hoisted_2 = { class: "drop-down-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronDownSvg = _resolveComponent("ChevronDownSvg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "d-flex justify-content-between",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
      }, [
        _renderSlot(_ctx.$slots, "label"),
        _createVNode(_component_ChevronDownSvg, { class: "drop-down-chevron" })
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass({
      'drop-down-list': true,
      'drop-down-is-open': _ctx.isOpen,
    }),
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleCloseOnSelect && _ctx.handleCloseOnSelect(...args)))
    }, [
      _renderSlot(_ctx.$slots, "list")
    ], 2)
  ], 512))
}