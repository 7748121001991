
import { defineComponent, ref } from 'vue';
import TheHeader from '@/components/TheHeader.vue';
import TheSidebar from '@/components/TheSidebar.vue';

export default defineComponent({
  name: 'CienaLayout',
  components: {
    TheHeader,
    TheSidebar,
  },
  props: {
    noSidebar: {
      type: Boolean,
      default: false,
    },
    noSearch: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['onSearchBarSubmit'],
  setup() {
    const isSidebarOpened = ref(false);

    const openSidebar = () => {
      isSidebarOpened.value = true;
    };
    const closeSidebar = () => {
      isSidebarOpened.value = false;
    };

    return {
      isSidebarOpened,
      openSidebar,
      closeSidebar,
    };
  },
});
