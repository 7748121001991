import req from '@/api/utilities/axios';
import {QuadrantItem} from '@/api/reportApi';
import ReleaseNotes from '@/views/ReleaseNotes.vue';
import {linkInterface, metaInterface} from '@/components/Pagination.vue';

export const apiGetReleaseNotes = (
  year: number,
  month: number | null,
  page: number,
): Promise<ReleaseNotesResponse> => req.post(`release-note?page=${page}`, {
  year, month: month === null ? undefined : month
}).then(r => r.data);


export interface ReleaseNotes {
  id: number;
  title: string;
  notes: string;
  release_date: string;
  created_at: string;
  updated_at: string;
}
export interface ReleaseNotesResponse  {
  data: Array<ReleaseNotes>;
  meta: metaInterface,
  links: linkInterface,
}

