
import { IQuestion, questionAnswerValues } from '@/types/question';
import { defineComponent, PropType } from 'vue';
import { ulid } from 'ulid';
import CloseSvg from '@/assets/img/close.vue';
import QuestionOptionInput from './QuestionOptionInput.vue';

export default defineComponent({
  name: 'OptionMultiple',
  components: {
    QuestionOptionInput,
    CloseSvg,
  },
  props: {
    question: {
      type: Object as PropType<IQuestion>,
      required: true,
    },
    ix: {
      type: Number,
      required: true,
    },
  },
  emits: ['updateAnswerOptions', 'removeAnswerOptionRule'],
  setup(props, ctx) {
    const getAnswerValueCopy = (): questionAnswerValues => JSON.parse(JSON.stringify(props.question.answer_values));

    const updateValueOptions = (payload: questionAnswerValues) => {
      ctx.emit('updateAnswerOptions', props.ix, payload);
    };

    const handleValueChange = (uuid: string, value: string) => {
      const currentAnswerValues = getAnswerValueCopy();
      currentAnswerValues[uuid] = value;
      updateValueOptions(currentAnswerValues);
    };

    const addNewAnswerValue = () => {
      const currentAnswerValues = getAnswerValueCopy();
      currentAnswerValues[ulid()] = '';
      updateValueOptions(currentAnswerValues);
    };

    const removeAnswerValue = (uuid: string) => {
      const currentAnswerValues = getAnswerValueCopy();
      delete currentAnswerValues[uuid];
      updateValueOptions(currentAnswerValues);
      ctx.emit('removeAnswerOptionRule', uuid);
    };

    return {
      handleValueChange,
      addNewAnswerValue,
      removeAnswerValue,
    };
  },
});
