import { BASE_URL } from '@/constants/app-config';
import axios from 'axios';
import { handleResponse, handleError } from './response';

const getAll = (resource: string) => axios
  .get(`${BASE_URL}/${resource}`)
  .then(handleResponse)
  .catch(handleError);

const getSingle = (resource: string, id: number | string) => axios
  .get(`${BASE_URL}/${resource}/${id}`)
  .then(handleResponse)
  .catch(handleError);

const post = (resource: string, model: any) => axios
  .post(`${BASE_URL}/${resource}`, model)
  .then(handleResponse)
  .catch(handleError);

const update = (resource: string, id: string | number, model: any) => axios
  .put(`${BASE_URL}/${resource}/${id}`, model)
  .then(handleResponse)
  .catch(handleError);

const patch = (resource: string, model: any) => axios
  .patch(`${BASE_URL}/${resource}`, model)
  .then(handleResponse)
  .catch(handleError);

const remove = (resource: string, id: number) => axios
  .delete(`${BASE_URL}/${resource}/${id}`)
  .then(handleResponse)
  .catch(handleError);

export default {
  getAll,
  getSingle,
  post,
  update,
  patch,
  remove,
};
