
import {defineComponent, ref} from 'vue';

export default defineComponent({
  name: 'QuestioOptionInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onValueChange'],
  setup(props, ctx) {
    const handleInputChange = (inputValue: string) => {
      ctx.emit('onValueChange', inputValue);
    };
    const textareaInitialRows = ref((props.value || '').length > 90 ? 2 : 1)

    return {
      handleInputChange,
      textareaInitialRows
    };
  },
});
