import {reactive, ref} from 'vue';

export default function useModal(isOpenProp: boolean) {
    const isOpen = ref<boolean>(isOpenProp);

    const close = () => isOpen.value = false
    const open = () => isOpen.value = true;
    return {
      isOpen,
      open,
      close,
    }
}
