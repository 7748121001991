
import { defineComponent, onMounted, ref, computed } from 'vue';
import ArchiveCard from '@/components/ArchiveCard.vue';
import Pagination from '@/components/Pagination.vue';
import { IQuiz } from '@/types/quiz';
import { routeNewDocument } from '@/core/constants';
import {getArchivedQuizApi, getQuizFilters, searchQuizApi, unArchiveQuizApi} from '@/api/QuizApi';
// import { useRouter } from 'vue-router';
import QuizFilters from "@/components/QuizFilters.vue";
import {key} from '@/store';
import {useStore} from 'vuex';

export default defineComponent({
  name: 'ArchivePage',
  components: {
    ArchiveCard,
    Pagination,
    QuizFilters,
  },
  setup() {
    const store = useStore(key);
    const getQuizPayload = computed(() => store.state.getQuizPayload);
    const rNewDocument = ref(routeNewDocument);
    const documents = ref<IQuiz[]>([]);
    // const router = useRouter();
    const pageMeta = ref({
      current_page: null,
      last_page: null,
      from: null,
      to: null,
      total: null,
    });
    const searchName = ref('');

    const businessFunctionId = ref(-1);
    onMounted(() => {
      getQuizFilters().then(res => {
        businessFunctionId.value = res['business_function'].question_template_id;
      })
    })

    const handleQuizResponse = (res: any) => {
      documents.value = res.data.data;
      pageMeta.value = res.data.meta;
    };

    const getQuizzes = (page = 1) => {
      getArchivedQuizApi(page).then(handleQuizResponse);
    };
    onMounted(() => {
      getQuizzes(1);
    });
    const handleSearch = (searchInputValue: string): void => {
      searchName.value = searchInputValue;
      searchQuizApi({
        ...getQuizPayload.value,
        page: 1,
        name: searchInputValue,
        show_only_archived: true,
      }).then(handleQuizResponse);
    };
    const navigateToPage = (page: number) => {
      getQuizzes(page);
    };

    const unarchiveQuiz = (quizId: number) => {
      unArchiveQuizApi(quizId).then(() => {
        getQuizzes(pageMeta.value.current_page || 1);
      });
    };

    const haveItems = computed(() => documents.value.length);

    const onFilterChange = (payload: any) => {
      searchQuizApi({
        ...getQuizPayload.value,
        page: 1,
        name: searchName.value,
        ...payload,
        show_only_archived: true,
      }).then(handleQuizResponse);
    }
    return {
      documents,
      rNewDocument,
      handleSearch,
      navigateToPage,
      unarchiveQuiz,
      pageMeta,
      haveItems,
      onFilterChange,
      businessFunctionId,
    };
  },
});
