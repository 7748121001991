
import {defineComponent, computed, PropType, onMounted, ref} from 'vue';
import documentGradients from '@/constants/documentGradients';
import OptionDropDown from '@/components/OptionDropDown/OptionDropDown.vue';
import OptionDropDownRow from '@/components/OptionDropDown/OptionDropDownRow.vue';
import TheGradient from '@/components/TheGradient.vue';
import ProgressBar from "@/components/ProgressBar.vue";
import {FilterValues} from "@/types/quiz";
import {getBusinessFunction} from '@/utils/utils';
import { format } from 'date-fns';

export default defineComponent({
  name: 'DocumentCard',
  methods: {
      getBusinessFunction
  },
  components: {
    ProgressBar,
    TheGradient,
    OptionDropDown,
    OptionDropDownRow,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    lastEdited: {
      type: String,
      required: true,
    },
    gradientType: {
      type: Number,
      required: true,
      validator(val: number) {
        return Object.keys(documentGradients).includes(val.toString(10));
      },
    },
    progress: {
      type: Number,
      required: true,
    },
    size: {
      type: String,
      default: 'medium',
      validator(value: string) {
        return ['small', 'medium'].includes(value);
      },
    },
    filterValues: {
      type: Object as PropType<FilterValues>,
      required: true,
    },
    businessFunctionId: {
      type: Number,
      required: true,
    },
    isUpdatePending: {
      type: Boolean,
      required: true,
},
  },
  emits: ['onArchive'],
  setup(props: { size: string; id: number, businessFunctionId: number }, ctx) {
    const isCardTypeSmall = computed(() => props.size === 'small');
    const archiveDocument = () => {
      ctx.emit('onArchive', props.id);
    };

    return {
      isCardTypeSmall,
      archiveDocument,
      format,
    };
  },
  computed: {
    getFormattedDate() {
      return (date: Date) => {
        const year = date.getFullYear();

        let month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : `0${month}`;

        let day = date.getDate().toString();
        day = day.length > 1 ? day : `0${day}`;

        return `${month}/${day}/${year}`;
      };
    },
  },
});
