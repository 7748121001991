

export default {
  name: 'NewCard',
  props: {
    name: {
      type: String,
      default: '',
      required: true,
    },
    cardBackground: {
      type: String,
      default: 'blue',
      validate(val: string): boolean {
        return ['red'].includes(val);
      },
    },
  },
  emits: ['handleClick'],
};
