import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_header = _resolveComponent("the-header")!
  const _component_the_sidebar = _resolveComponent("the-sidebar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_the_header, {
      "is-opened": _ctx.isSidebarOpened,
      class: _normalizeClass({ 'no-sidebar': _ctx.noSidebar }),
      noSearch: _ctx.noSearch,
      onOnSearchBarSubmit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onSearchBarSubmit', $event))),
      onOnOpenSidebar: _ctx.openSidebar
    }, {
      "header-left": _withCtx(() => [
        _renderSlot(_ctx.$slots, "header-left")
      ]),
      _: 3
    }, 8, ["is-opened", "class", "noSearch", "onOnOpenSidebar"]),
    (!_ctx.noSidebar)
      ? (_openBlock(), _createBlock(_component_the_sidebar, {
          key: 0,
          "is-opened": _ctx.isSidebarOpened,
          onOnCloseSidebar: _ctx.closeSidebar
        }, null, 8, ["is-opened", "onOnCloseSidebar"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(`main ${_ctx.noSidebar ? 'no-sidebar' : ''}`)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ]))
}