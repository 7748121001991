
import {defineComponent, computed, PropType, onMounted, ref} from 'vue';
import documentGradients from '@/constants/documentGradients';
import TheGradient from '@/components/TheGradient.vue';
import {FilterValues} from "@/types/quiz";
import CheckedSvg from '@/assets/img/checked.vue';
import {getBusinessFunction} from '@/utils/utils';
export default defineComponent({
  name: 'DocumentCardCheckbox',
  methods: {
    getBusinessFunction
  },
  components: {
    TheGradient,
    CheckedSvg,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    lastEdited: {
      type: String,
      required: true,
    },
    gradientType: {
      type: Number,
      required: true,
      validator(val: number) {
        return Object.keys(documentGradients).includes(val.toString(10));
      },
    },
    filterValues: {
      type: Object as PropType<FilterValues>,
      required: true,
    },
    businessFunctionId: {
      type: Number,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
    fillColor: {
      type: String,
      default: '#0550D1'
    }
  },
  emits: ['onCheck'],
  computed: {
    getFormattedDate() {
      return (date: Date) => {
        const year = date.getFullYear();

        let month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : `0${month}`;

        let day = date.getDate().toString();
        day = day.length > 1 ? day : `0${day}`;

        return `${month}/${day}/${year}`;
      };
    },
  },
});
