import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import DashboardPage from '@/views/DashboardPage.vue';
// import TemplatePage from '@/views/TemplatePage.vue';
import ArchivePage from '@/views/ArchivePage.vue';
import NewDocumentPage from '@/views/NewDocumentPage.vue';
import QuestionLayout from '@/views/QuestionLayout.vue';
import LogIn from '@/views/LogInPage.vue';
import LoginCallbackPage from '@/views/LoginCallbackPage.vue'
import {
  routeAnswerQuestion,
  routeArchive,
  routeBulkDownloadSelectedPartners,
  routeDashboard,
  routeEditQuestion,
  routeLogIn, routeMaintenance,
  routeNewDocument,
  routeQuestion,
  routeQuestionDeleteSelectPartner, routeQuestionEditSelectPartner,
  routeQuestionSelectPartner, routeReleaseNotes,
  routeReport,
  routeUpload
} from '@/core/constants';
import store from "@/store";
import {IS_LOGIN_ENABLED} from "@/constants/app-config";
import ReportPage from "@/views/ReportPage.vue";
import QuestionPage from "@/views/QuestionPage.vue";
import UploadPage from "@/views/UploadPage.vue";
import QuestionSelectPartners from '@/views/QuestionSelectPartners.vue';
import QuestionSelectDeletePartners from '@/views/QuestionSelectDeletePartners.vue';
import QuestionSelectEditPartners from '@/views/QuestionSelectEditPartners.vue';
import BulkDownloadSelectedPartners from '@/views/BulkDownloadSelectedPartners.vue';
import MaintenancePage from '@/views/MaintenancePage.vue';
import ReleaseNotesPage from '@/views/ReleaseNotes.vue';


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: routeDashboard,
    component: DashboardPage,
    meta: {
      requiresAuth: IS_LOGIN_ENABLED
    }
  },
  // {
  //   path: '/template',
  //   name: routeTemplate,
  //   component: TemplatePage,
  // },
  {
    path: '/archive',
    name: routeArchive,
    component: ArchivePage,
    meta: {
      requiresAuth: IS_LOGIN_ENABLED
    }
  },
  {
    path: '/new-document',
    name: routeNewDocument,
    component: NewDocumentPage,
    meta: {
      requiresAuth: IS_LOGIN_ENABLED
    }
  },
  {
    path: '/report',
    name: routeReport,
    component: ReportPage,
    meta: {
      requiresAuth: IS_LOGIN_ENABLED
    }
  },
  {
    path: '/question/:id',
    name: routeQuestion,
    component: QuestionPage,
    meta: {
      requiresAuth: IS_LOGIN_ENABLED
    }
  },
  {
    path: '/question/:id/select-partner',
    name: routeQuestionSelectPartner,
    component: QuestionSelectPartners,
    meta: {
      requiresAuth: IS_LOGIN_ENABLED
    }
  },
  {
    path: '/question/:id/select-delete-partner',
    name: routeQuestionDeleteSelectPartner,
    component: QuestionSelectDeletePartners,
    meta: {
      requiresAuth: IS_LOGIN_ENABLED
    }
  },
  {
    path: '/question/:id/select-edit-partner',
    name: routeQuestionEditSelectPartner,
    component: QuestionSelectEditPartners,
    meta: {
      requiresAuth: IS_LOGIN_ENABLED
    }
  },
  {
    path: '/download-selected-partner',
    name: routeBulkDownloadSelectedPartners,
    component: BulkDownloadSelectedPartners,
    meta: {
      requiresAuth: IS_LOGIN_ENABLED
    }
  },
  {
    path: '/upload',
    name: routeUpload,
    component: UploadPage,
    meta: {
      requiresAuth: IS_LOGIN_ENABLED
    }
  },
  {
    path: '/maintenance',
    name: routeMaintenance,
    component: MaintenancePage,
  },
  {
    path: '/release-notes',
    name: routeReleaseNotes,
    component: ReleaseNotesPage,
  },
  {
    path: '/:pathMatch(.*)*',
    component: DashboardPage,
    meta: {
      requiresAuth: IS_LOGIN_ENABLED
    },
  }
];

if(IS_LOGIN_ENABLED) {
  routes.push({ path: '/saml/callback/:code', component: LoginCallbackPage })
  routes.push({ path: '/login', name: routeLogIn, component: LogIn},)
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach( (to, from, next) => {
  if (to.meta.requiresAuth && !store.state.isAuthenticated && IS_LOGIN_ENABLED) next({ name: routeLogIn })
  else next()
})

export default router;
