
import {computed, defineComponent, onMounted, ref} from 'vue';
import Pagination from '@/components/Pagination.vue';
import { IQuiz } from '@/types/quiz';
import { routeQuestion } from '@/core/constants';
import {
  apiGetQuiz,
  getQuizFilters,
  GetQuizRequestFiltersPayload,
  searchQuizApi
} from '@/api/QuizApi';
import {useRoute, useRouter} from 'vue-router';
import QuizFilters from "@/components/QuizFilters.vue";
import {useStore} from 'vuex';
import {key} from '@/store';
import BaseButton from '@/components/BaseButton.vue';
import {apiEditQuestionNew} from '@/api/QuestionApi';
import TheModal from '@/components/TheModal.vue';
import DocumentCardCheckbox from '@/components/DocumentCardCheckbox.vue';
import useModal from '@/hooks/useModal';

export default defineComponent({
  name: 'QuestionSelectEditPartners',
  components: {
    QuizFilters,
    DocumentCardCheckbox,
    Pagination,
    BaseButton,
    TheModal,
  },
  setup() {

    const route = useRoute();
    const router = useRouter();
    const store = useStore(key);

    const confirmEditModal = useModal(false);

    const storeEditQuestionData = computed(() => store.state.editQuestion);
    const questionToEdit = computed(() => store.state.editQuestion.question);
    const savedQuizIds = computed(() => store.state.createQuestion.quizIds);
    const quizPayload = computed(() => store.state['getQuizPayload'])
    const storeKeepAnswer = computed(() => store.state.keep_answer);

    const {
      params: { id },
    } = route;
    const quizId = parseInt(id.toString(), 10);
    const name = ref('');
    const color = ref(1);
    const questionCreateConfirmIsOpen = ref(false);
    const documents = ref<IQuiz[]>([]);
    const pageMeta = ref({
      current_page: null,
      last_page: null,
      from: null,
      to: null,
      total: null,
    });
    const businessFunctionId = ref(-1);

    onMounted(() => {
      getQuizFilters().then(res => {
        businessFunctionId.value = res['business_function'].question_template_id;
      });
      apiGetQuiz(quizId).then((res: IQuiz | any) => {
        name.value = res.name;
        color.value = res.color;
      });
    })

    const handleQuizResponse = (res: any) => {
      documents.value = res.data.data;
      pageMeta.value = res.data.meta;
    };

    const getQuizzes = (page = 1) => {
      const payload = {
        ...quizPayload.value,
        page,
      }
      if(questionToEdit.value?.id) {
        if(!!payload.filter) {
          payload['filter']['question_id_name'] = questionToEdit.value.id;
        } else {
          payload['filter'] = {
            question_id_name: questionToEdit.value.id,
            question_ids: {}
          }
        }
      }
      store.commit('setQuizPayload', payload);
      searchQuizApi(payload).then(handleQuizResponse);
    };
    onMounted(() => {
      const payload = {
        ...quizPayload.value,
      };
      if(questionToEdit.value?.id) {
        if(!!payload.filter) {
          payload['filter']['question_id_name'] = questionToEdit.value.id;
        } else {
          payload['filter'] = {
            question_id_name: questionToEdit.value.id,
            question_ids: {}
          }
        }
      }
      searchQuizApi(payload).then(handleQuizResponse);
      store.commit('quizIdsToggle', quizId);
    });
    const toggleQuiz = (quizIdValue: number) => {
      const isCurrentQuizToggled = quizIdValue === quizId;
      if(isCurrentQuizToggled) return
      store.commit('quizIdsToggle', quizIdValue);
    };
    const handleSearch = (searchInputValue: string): void => {
      const payload = {
        ...quizPayload.value,
        name: searchInputValue,
        page: 1,
      }
      if(questionToEdit.value?.id) {
        if(!!payload.filter) {
          payload['filter']['question_id_name'] = questionToEdit.value.id;
        } else {
          payload['filter'] = {
            question_id_name: questionToEdit.value.id,
            question_ids: {}
          }
        }
      }
      store.commit('setQuizPayload', payload)

      searchQuizApi(payload).then(handleQuizResponse);
    };
    const navigateToPage = (page: number) => {
      getQuizzes(page);
    };

    function onFilterChange(data: GetQuizRequestFiltersPayload) {
      const payload = {
        ...quizPayload.value,
        ...data,
        page: 1,
      }
      if(questionToEdit.value?.id) {
        if(!!payload.filter) {
          payload['filter']['question_id_name'] = questionToEdit.value.id;
        } else {
          payload['filter'] = {
            question_id_name: questionToEdit.value.id,
            question_ids: {}
          }
        }
      }
      store.commit('setQuizPayload', payload);
      searchQuizApi(payload).then(handleQuizResponse);
    }

    function update() {
      // @todo
      if(!questionToEdit.value) throw new Error("Question doesn't exist");
      if(storeKeepAnswer.value === null) throw new Error('Keep answers is not selected');

      if(storeEditQuestionData.value.question?.id && storeEditQuestionData.value.question) {
        apiEditQuestionNew(
          quizId,
          storeEditQuestionData.value.question.id,
          savedQuizIds.value,
          storeEditQuestionData.value.question,
          storeKeepAnswer.value
        )
          .then(() => {
            store.commit('setKeepAnswer', null);
            confirmEditModal.close();
            questionCreateConfirmIsOpen.value = true;
            store.commit('setCreatedQuestionInitialState');
            store.commit('setInitiateEditQuestion', {
              isEditQuestionOpen: false,
              question: null,
              quizIds: [],
            });
            store.commit('setQuestionEditMode', false);
          })
      }


    }

    function cancel() {
      store.commit('quizIdsSet', []);
      router.push({
        name: routeQuestion,
        params: {
          id: quizId,
        },
      });
    }

    function selectAll() {
      store.commit('quizIdsAdd', documents.value.map(el => el.id));
    }
    function deselectAll() {
      store.commit('quizIdsOmit', documents.value.map(el => el.id));
      store.commit('quizIdsToggle', quizId);
    }
    return {
      documents,
      handleSearch,
      navigateToPage,
      pageMeta,
      onFilterChange,
      businessFunctionId,

      update,
      cancel,

      questionCreateConfirmIsOpen,
      savedQuizIds,
      toggleQuiz,

      selectAll,
      deselectAll,

      confirmEditModal,
    };
  },
});
