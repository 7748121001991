

import {defineComponent} from 'vue';
import CircleProgress from "vue3-circle-progress";
export default defineComponent({
  name: 'UploadPageProgress',
  components: {
    CircleProgress,
  },
  props: {
    percentProgress: {
      type: Number,
      default: 0
    },
    state: {
      type: String,
      default: ''
    }
  }
})
