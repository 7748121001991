
import { defineComponent, ref, PropType, computed } from 'vue';
import ChevronDownSvg from '@/assets/img/chevron-down.vue';
import { onClickOutside } from '@vueuse/core';
import CloseSvg from '@/assets/img/close.vue';
export default defineComponent({
  name: 'SelectInput',
  components: {
    ChevronDownSvg,
    CloseSvg,
  },
  emits: ['handleChange'],
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
      default: 'Select..',
    },
    options: {
      type: Array as PropType<Array<{ label: string; value: string }>>,
      default: () => [],
      required: true,
    },
  },
  setup(props, ctx) {
    const isOpened = ref(false);
    const selectInputRef = ref(null);

    const openDropDown = () => {
      isOpened.value = true;
    };
    const closeDropDownFn = () => {
      isOpened.value = false;
    };

    onClickOutside(selectInputRef, () => {
      closeDropDownFn();
    });

    const selectOption = (value: string) => {
      ctx.emit('handleChange', value);
      closeDropDownFn();
    };

    const getSelectValue = computed(() => {
      let ret = props.placeholder;
      if (props.value) {
        props.options.forEach((el) => {
          if (el.value === props.value) {
            ret = el.label;
          }
        });
      }
      return ret;
    });
    return {
      isOpened,
      selectOption,
      getSelectValue,
      openDropDown,
      selectInputRef,
    };
  },
});
