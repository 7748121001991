import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7194662f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "document-card-option-place" }
const _hoisted_2 = { class: "document-card-name heading-5 black mb-8" }
const _hoisted_3 = { class: "d-flex" }
const _hoisted_4 = {
  key: 0,
  class: "document-card-business-function"
}
const _hoisted_5 = { class: "document-card-edited p3 grey-1 d-flex justify-content-between align-items-center" }
const _hoisted_6 = { class: "document-card-last-edited" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_gradient = _resolveComponent("the-gradient")!
  const _component_delete_svg = _resolveComponent("delete-svg")!
  const _component_option_drop_down_row = _resolveComponent("option-drop-down-row")!
  const _component_option_drop_down = _resolveComponent("option-drop-down")!
  const _component_Progress_bar = _resolveComponent("Progress-bar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      'document-card': true,
      'document-card-small': _ctx.isCardTypeSmall,
    })
  }, [
    _createVNode(_component_the_gradient, { "gradient-type": _ctx.gradientType }, null, 8, ["gradient-type"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_option_drop_down, null, {
        default: _withCtx(() => [
          _createVNode(_component_option_drop_down_row, {
            text: "Unarchive",
            onHandleClick: _ctx.unarchiveCard
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_delete_svg)
            ]),
            _: 1
          }, 8, ["onHandleClick"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.name), 1),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.getBusinessFunction(_ctx.filterValues))
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.getBusinessFunction(_ctx.filterValues)), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", _hoisted_6, "Last edited: " + _toDisplayString(_ctx.format(new Date(_ctx.lastEdited), "MMM d, yyyy")), 1),
      _createVNode(_component_Progress_bar, { progress: _ctx.progress }, null, 8, ["progress"])
    ])
  ], 2))
}