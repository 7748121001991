
import ChevronDownSvg from "@/assets/img/chevron-down.vue";
import {PropType, defineComponent, ref, computed} from "vue";
import {IQuestion, questionRules} from '@/types/question';
import { onClickOutside } from '@vueuse/core';
import CheckedSvg from '@/assets/img/checked.vue';
import question from '@/components/Question/Question.vue';
import {id} from 'date-fns/locale';
export default defineComponent({
  name: 'RuleMultipleSelect',
  components: {
    ChevronDownSvg,
    CheckedSvg,
  },
  props: {
    question: {
      type: Object as PropType<IQuestion>,
      required: true,
    },
    answerValueId: {
      type: String,
      required: true,
    },
    options: {
      type: Object as PropType<{label: string, value: number}[]>,
      required: true,
    },
    rules: {
      type: Object as PropType<{ answer_value_key: string, question_ids: number[] }[]>,
      required: true,
    }
  },
  emits: ['onUpdate'],
  setup(props,ctx) {
    const isOpen = ref(false);
    const searchValue = ref('');
    const searchMultipleSelectWrapper = ref(null);
    // console.log(props.question);
    const selectedItems = ref([])
    console.log(selectedItems.value);
    onClickOutside(searchMultipleSelectWrapper, (event) => {
      isOpen.value = false;
    });


    const isItemSelected = (option: {label: string, value: number}) => {
      let ret = false;
      const questionId = option.value;
      const answerValueId = props.answerValueId;
      props.rules.forEach(rule => {
        if(rule.answer_value_key === answerValueId && rule.question_ids.indexOf(questionId) !== -1) {
          ret = true;
        }
      })
      return ret;
    }

    const inputPlaceholder = computed(() => {
      let str = '';
      let selectedIds: number[] = []
      props.rules.forEach(ruleEl => {
        if(ruleEl.answer_value_key === props.answerValueId) {
          selectedIds = [...ruleEl.question_ids]
        }
      })
      console.log({selectedIds})
      props.options.forEach(option => {
        console.log(option);

        if(selectedIds.indexOf(option.value) !== -1) {
          str += `${option.label}, `;
        }

      })

      return  str;
    })


    const getSearchSelectInputOptions = (
      answerValues: Record<string, string>,
    ): Array<{ name: string, id: string}> => {
      const response: Array<{ name: string, id: string}> = [];
      Object.keys(answerValues).forEach((key) => {
        if(answerValues[key].indexOf(searchValue.value ) !== -1) {
          response.push({
            name: answerValues[key], id: key,
          });
        }
      });
      return response;
    };

    const arrayIncludeOrOmit = (array: Array<string>, value: string): Array<string> => {
      const ix = array.indexOf(value);
      if (ix !== -1) {
        array.splice(ix, 1);
      } else {
        array.push(value);
      }
      return array;
    };

    const onItemClick = (question: {label: string, value: number}) => {
      ctx.emit('onUpdate', {answer_value_key: props.answerValueId, question_id: question.value});
    }


    return {
      getSearchSelectInputOptions,
      isOpen,
      searchMultipleSelectWrapper,
      selectedItems,
      onItemClick,
      searchValue,
      inputPlaceholder,
      isItemSelected,
    }
  }
});
