
import {defineComponent} from "vue";

export default defineComponent({
  name: "TheModal",
  emits: ['onCancel', 'onConfirm'],
  props: {
    isVisible: Boolean
  },
})
