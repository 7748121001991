
import {computed, defineComponent, ref} from 'vue';
import {useStore} from 'vuex';
import store, {key} from '@/store';
import DropDown from '@/components/DropDown.vue';
import TheModal from '@/components/TheModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import {apiEditQuestionNew, apiRemoveQuestionNew} from '@/api/QuestionApi';
import {useRouter} from 'vue-router';
import {routeQuestionDeleteSelectPartner, routeQuestionEditSelectPartner} from '@/core/constants';
import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue';
import useModal from '@/hooks/useModal';
import CheckedSvg from '@/assets/img/checked.vue';
import SelectInput from '@/components/SelectInput.vue';
import question from '@/components/Question/Question.vue';
import option from '@/assets/img/option.vue';
import {notify} from '@kyvg/vue3-notification';
import GrayText from '@/components/GrayText.vue';

export default defineComponent({
  name: 'EditQuestionQuizModal',
  computed: {
    option() {
      return option
    }, store() {
      return store
    }, question() {
      return question
    }
  },
  components: {
    GrayText,
    DropDown,
    TheModal,
    BaseButton,
  },
  props: {
    currentPartnerQuizId: {
      type: Number,
      required: true,
    }
  },
  setup(props) {
    const store = useStore(key);
    const isOpen = computed(() => store.state.editQuestion.isEditQuestionOpen);
    const storeEditQuestionData = computed(() => store.state.editQuestion);
    const storeKeepAnswer = computed(() => store.state.keep_answer);

    const router = useRouter();


    const updateQuestionWasSubmitted = ref(false);

    const questionCreateConfirmIsOpen = ref(false);
    const deleteValue = ref<string>('');

    const canUpdateQuestion = computed(
      () => (deleteValue.value.toLowerCase() === 'delete' && (storeKeepAnswer.value === false) || storeKeepAnswer.value === true || storeKeepAnswer.value === null )
    );

    interface OptionI {
      placeholderName: string;
      name: string;
      id: number;
    }

    const CREATE_QUESTION_CURRENT_PARTNER_ID = 1;
    const CREATE_QUESTION_ALL_PARTNER_ID = 2;
    const CREATE_QUESTION_SELECT_PARTNER_ID = 3;
    const currentOption = ref<OptionI>({
      placeholderName: 'to this one Partner',
      name: 'Edit Question to this Partner only',
      id: 1
    });
    const options = ref<Array<OptionI>>([
      {
        placeholderName: 'to this one Partner',
        name: 'Edit Question to this Partner only',
        id: CREATE_QUESTION_CURRENT_PARTNER_ID
      },
      {
        placeholderName: 'to all Partners',
        name: 'Edit Question for all Partners',
        id: CREATE_QUESTION_ALL_PARTNER_ID
      },
      {
        placeholderName: 'to selected Partners',
        name: 'Edit Question to selected Partners',
        id: CREATE_QUESTION_SELECT_PARTNER_ID
      },
    ]);

    const onEdit = () => {

      const optionId = currentOption.value.id;
      if (storeKeepAnswer.value === null) {
        updateQuestionWasSubmitted.value = true;
        notify({
          title: 'Please select required field',
          type: 'error',
          duration: 5000,
          closeOnClick: true,
        });
        throw new Error('Keep answer should be selected')
      }
      if(!canUpdateQuestion.value) throw new Error('Need confirmation to be deleted');

      if (optionId === CREATE_QUESTION_SELECT_PARTNER_ID) {


        router.push({
          name: routeQuestionEditSelectPartner,
          params: {
            id: props.currentPartnerQuizId,
          },
        });
        return;
      }

      let partnerIds: Array<number> = [];
      if (optionId === CREATE_QUESTION_CURRENT_PARTNER_ID) {
        partnerIds = [props.currentPartnerQuizId];
      }
      if (!storeEditQuestionData.value.question) throw new Error("Question doesn't exist");

      apiEditQuestionNew(
        props.currentPartnerQuizId,
        storeEditQuestionData.value.question.id,
        partnerIds, storeEditQuestionData.value.question,
        storeKeepAnswer.value,
      )
        .then(() => {
          updateQuestionWasSubmitted.value = false;
          closeModal();
          questionCreateConfirmIsOpen.value = true;
        })
    }
    const onCancel = () => {
      closeModal();
    }

    const closeModal = () => {
      store.commit('setKeepAnswer', null);
      deleteValue.value = '';
      store.commit('setInitiateEditQuestion', {
        isEditQuestionOpen: false,
        question: null,
        quizIds: [],
      });
      store.commit('setQuestionEditMode', false);
    }

    const setOption = (opt: OptionI) => {
      currentOption.value = opt;
    }

    const setKeepAnswer = (payload: boolean) => {
      store.commit('setKeepAnswer', payload);
    }

    return {
      isOpen,
      onCancel,
      options,
      setOption,
      currentOption,
      questionCreateConfirmIsOpen,
      onEdit,
      CREATE_QUESTION_ALL_PARTNER_ID,
      CREATE_QUESTION_SELECT_PARTNER_ID,
      CREATE_QUESTION_CURRENT_PARTNER_ID,
      storeKeepAnswer,
      setKeepAnswer,
      updateQuestionWasSubmitted,
      canUpdateQuestion,
      deleteValue,
    }
  }
});
