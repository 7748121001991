import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ce697596"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "import-text" }
const _hoisted_2 = { class: "drop-down-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_type_text_svg = _resolveComponent("type-text-svg")!
  const _component_chevron_down_svg = _resolveComponent("chevron-down-svg")!
  const _component_option_drop_down_row = _resolveComponent("option-drop-down-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "import",
      ref: "wrapper",
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.isOpen = true))
    }, [
      _createVNode(_component_type_text_svg),
      _createElementVNode("span", _hoisted_1, _toDisplayString($props.label), 1),
      _createVNode(_component_chevron_down_svg)
    ], 512),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createVNode(_component_option_drop_down_row, {
        text: "Import additional data",
        onHandleClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onTypeChange', 1)))
      }),
      _createVNode(_component_option_drop_down_row, {
        text: "Delete all data / import new data",
        onHandleClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('onTypeChange', 2)))
      }),
      ($props.havePermissionToDownload)
        ? (_openBlock(), _createBlock(_component_option_drop_down_row, {
            key: 0,
            text: "Download all partner data",
            onHandleClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('onTypeChange', 3)))
          }))
        : _createCommentVNode("", true),
      ($props.havePermissionToDownload)
        ? (_openBlock(), _createBlock(_component_option_drop_down_row, {
            key: 1,
            text: "Download selected partners data",
            onHandleClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('onTypeChange', 4)))
          }))
        : _createCommentVNode("", true)
    ], 512), [
      [_vShow, $setup.isOpen]
    ])
  ], 64))
}