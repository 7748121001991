import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "question-actions-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isAddRuleAvailable)
      ? (_openBlock(), _createBlock(_component_base_button, {
          key: 0,
          class: "mr-6",
          onClick: _ctx.handleAddRule
        }, {
          default: _withCtx(() => [
            _createTextVNode("Add rule")
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true),
    (!_ctx.haveNewQuestionCreationInitiated)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass({
        'mr-6': true,
        'two-items': !_ctx.isAddRuleAvailable,
      })
        }, [
          _createVNode(_component_base_button, { onClick: _ctx.handleAddQuestion }, {
            default: _withCtx(() => [
              _createTextVNode("Add question")
            ]),
            _: 1
          }, 8, ["onClick"])
        ], 2))
      : _createCommentVNode("", true)
  ]))
}