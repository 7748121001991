<template>
  <div v-if="options" class="dropdown">
    <chevron-down-svg
      :class="{
        'select-input-chevron': true,
        'select-input-chevron-open': optionsShown,
      }"
    />
    <input
      v-model="searchFilter"
      class="dropdown-input"
      :name="name"
      :disabled="disabled"
      :placeholder="placeholder"
      @focus="showOptions()"
      @blur="exit()"
      @keyup="keyMonitor"
    />
    <div
      v-show="optionsShown"
      class="dropdown-content"
    >
      <div
        v-for="(option, index) in filteredOptions"
        :key="index"
        class="dropdown-item"
        @mousedown="selectOption(option)">
        {{ option.name || option.id || '-' }}
      </div>
    </div>
  </div>
</template>

<script>
import ChevronDownSvg from "@/assets/img/chevron-down.vue";
export default {
  name: 'SearchDropDown',
  components: {
    ChevronDownSvg,
  },
  props: {
    name: {
      type: String,
      required: false,
      default: 'dropdown',
      note: 'Input name'
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
      note: 'Options of dropdown. An array of options with id and name',
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Please select an option',
      note: 'Placeholder of dropdown'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Disable the dropdown'
    },
    defaultValue: {
      type: Object, // {name: string, id: string}
      required: true,
    }
  },
emits: ['filter', 'selected'],
  data() {
    return {
      selected: {},
      optionsShown: false,
      searchFilter: ''
    }
  },
  computed: {
    filteredOptions() {
      const filtered = [];
      const regOption = new RegExp(this.searchFilter, 'ig');
      for (const option of this.options) {
        if (this.searchFilter.length < 1 || option.name.match(regOption)){
          filtered.push(option);
        }
      }
      return filtered;
    }
  },
  watch: {
    searchFilter() {
      if (this.filteredOptions.length === 0) {
        this.selected = {};
      }
      // else {
      //   this.selected = this.filteredOptions[0];
      // }
      this.$emit('filter', this.searchFilter);
    }
  },
  created() {
    // this.$emit('selected', this.selected);
    this.selected = this.defaultValue;
    if(this.defaultValue) {
      this.searchFilter = this.defaultValue.name || '';
    }

  },
  methods: {
    selectOption(option) {
      this.selected = option;
      this.optionsShown = false;
      this.searchFilter = this.selected.name;
      this.$emit('selected', this.selected);
    },
    showOptions(){
      if (!this.disabled) {
        this.searchFilter = '';
        this.optionsShown = true;
      }
    },
    exit() {
      if (!this.selected.id) {
        this.selected = {};
        this.searchFilter = '';
      } else {
        this.searchFilter = this.selected.name;
      }
      // this.$emit('selected', this.selected);
      this.optionsShown = false;
    },
    // Selecting when pressing Enter
    keyMonitor: function(event) {
      if (event.key === "Enter" && this.filteredOptions[0])
        this.selectOption(this.filteredOptions[0]);
    }
  },
  template: 'Dropdown'
};
</script>


<style lang="sass" scoped>
.dropdown
  position: relative
  display: block
  margin: auto
  .dropdown-input
    line-height: 48px
    width: 100%
    padding: 0 50px 0 16px
    font-family: Inter, sans-serif
    color: #8D9091
    background: #fff
    border: 1px solid #EFEFEF
    border-radius: 8px
    transition: .3s
    &:hover
      background: #f8f8fa
      border-color: #264AC9

  .dropdown-content
    position: absolute
    max-height: 248px
    border: 1px solid #e7ecf5
    box-shadow: 0px -8px 34px 0px rgba(0,0,0,0.05)
    overflow: auto
    z-index: 1
    border-radius: 8px
    filter: drop-shadow(0px 25px 35px rgba(0, 0, 0, 0.06))
    padding: 4px
    background-color: #fff
    width: 100%
    .dropdown-item
      text-decoration: none
      display: block
      cursor: pointer
      font-family: Inter, sans-serif
      font-size: 14px
      //color: #8D9091
      line-height: 48px
      padding: 0 12px
      border-radius: 8px
      &:hover
        background-color: #F7F7F7

  .dropdown:hover .dropdown-content
    display: block

.select-input-chevron
  position: absolute
  top: 50%
  transform: translateY(-50%)
  right: 16px
  pointer-events: none
.select-input-chevron-open
  transform: translateY(-50%) rotate(180deg)
  pointer-events: initial

</style>
