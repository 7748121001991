import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, createBlock as _createBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a853423"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "qwerty bg-white" }
const _hoisted_2 = { class: "question-category-wrapper" }
const _hoisted_3 = { class: "question-list-wrapper" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 2,
  ref: "questionList"
}
const _hoisted_7 = { key: 3 }
const _hoisted_8 = {
  key: 0,
  class: "d-flex justify-content-end w-100 pt-20 pb-80"
}
const _hoisted_9 = {
  key: 1,
  class: "d-flex justify-content-end w-100 pt-20 pb-80"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuestionHeader = _resolveComponent("QuestionHeader")!
  const _component_question_category = _resolveComponent("question-category")!
  const _component_question_attachments = _resolveComponent("question-attachments")!
  const _component_question_activity = _resolveComponent("question-activity")!
  const _component_question = _resolveComponent("question")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_question_answer = _resolveComponent("question-answer")!
  const _component_add_question_to_quiz_modal = _resolveComponent("add-question-to-quiz-modal")!
  const _component_remove_question_from_quiz_modal = _resolveComponent("remove-question-from-quiz-modal")!
  const _component_edit_question_quiz_modal = _resolveComponent("edit-question-quiz-modal")!
  const _component_ciena_layout = _resolveComponent("ciena-layout")!

  return (_openBlock(), _createBlock(_component_ciena_layout, { "no-sidebar": true }, {
    default: _withCtx(() => [
      _createVNode(_component_QuestionHeader),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_question_category)
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.currentCategory === _ctx.ATTACHMENTS_ID)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_question_attachments, { "quiz-id": _ctx.quizId }, null, 8, ["quiz-id"])
              ]))
            : (_ctx.currentCategory === _ctx.ACTIVITY_ID)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_question_activity, { "quiz-id": _ctx.quizId }, null, 8, ["quiz-id"])
                ]))
              : (_ctx.isQuestionEditMode)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_component_draggable, {
                      modelValue: _ctx.questions,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.questions) = $event)),
                      group: "people",
                      "item-key": "id",
                      onChange: _ctx.onDragChange,
                      onStart: _cache[1] || (_cache[1] = ($event: any) => (_ctx.drag = true)),
                      onEnd: _cache[2] || (_cache[2] = ($event: any) => (_ctx.drag = false))
                    }, {
                      item: _withCtx(({ element, index }) => [
                        _createVNode(_component_question, {
                          ix: index,
                          id: element.id,
                          question: element,
                          "is-active": _ctx.isQuestionActive(element.id),
                          "is-editing-rules": _ctx.isEditingQuestionRule(element.id),
                          "all-questions": _ctx.getAllQuestionsIfIsEditingRule(element.id),
                          onHandleTypeChange: _ctx.handleTypeChange,
                          onHandleClick: _ctx.handleQuestionClick,
                          onHandleAddQuestion: _ctx.handleAddQuestion,
                          onHandleQuestionTitleChange: _ctx.handleQuestionTitleChange,
                          onUpdateAnswerOptions: _ctx.updateAnswerOptions,
                          onRemoveQuestion: _ctx.handleRemoveQuestion,
                          onHandleAddRule: _ctx.handleAddRule,
                          onHandleCancelRule: _ctx.handleCancelRule,
                          onHandleSaveRule: _ctx.handleSaveRule,
                          onRemoveAnswerOptionRule: _ctx.removeAnswerOptionRule,
                          onOnSaveAddedQuestion: _ctx.onSaveAddedQuestion,
                          onOnCancelAddedQuestion: _ctx.onCancelAddedQuestion,
                          onOnSaveEditedQuestion: _ctx.onSaveEditedQuestion,
                          onOnCancelEditedQuestion: _ctx.onCancelEditedQuestion
                        }, null, 8, ["ix", "id", "question", "is-active", "is-editing-rules", "all-questions", "onHandleTypeChange", "onHandleClick", "onHandleAddQuestion", "onHandleQuestionTitleChange", "onUpdateAnswerOptions", "onRemoveQuestion", "onHandleAddRule", "onHandleCancelRule", "onHandleSaveRule", "onRemoveAnswerOptionRule", "onOnSaveAddedQuestion", "onOnCancelAddedQuestion", "onOnSaveEditedQuestion", "onOnCancelEditedQuestion"])
                      ]),
                      _: 1
                    }, 8, ["modelValue", "onChange"])
                  ], 512))
                : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questions, (question, ix) => {
                      return _withDirectives((_openBlock(), _createBlock(_component_question_answer, {
                        key: question.id,
                        ix: ix,
                        question: question,
                        "is-active": _ctx.isQuestionActive(question.id),
                        error: _ctx.formErrors[question.id] && _ctx.formErrors[question.id][0] || '',
                        count: _ctx.getQuestionCount(question.id),
                        onHandleClick: _ctx.handleQuestionClickFromAnswerPage,
                        onHandleValueUpdate: _ctx.handleValueUpdate,
                        onRemoveQuestion: _ctx.handleRemoveQuestion,
                        onHandleAddQuestion: _ctx.handleAddQuestion
                      }, null, 8, ["ix", "question", "is-active", "error", "count", "onHandleClick", "onHandleValueUpdate", "onRemoveQuestion", "onHandleAddQuestion"])), [
                        [_vShow, _ctx.questionIdsToHide.indexOf(question.id) === -1]
                      ])
                    }), 128)),
                    (_ctx.questions.length !== 0)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          (_ctx.currentCategory === _ctx.categories[_ctx.categories.length - 1].id)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                _createElementVNode("span", {
                                  class: "default-link",
                                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push({ name: 'dashboard'})))
                                }, "Back To Dashboard")
                              ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                _createElementVNode("span", {
                                  class: "default-link",
                                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toNextCategory && _ctx.toNextCategory(...args)))
                                }, "Go To Next")
                              ]))
                        ], 64))
                      : _createCommentVNode("", true)
                  ]))
        ])
      ]),
      _createVNode(_component_add_question_to_quiz_modal, { "current-partner-quiz-id": _ctx.quizId }, null, 8, ["current-partner-quiz-id"]),
      _createVNode(_component_remove_question_from_quiz_modal, { "current-partner-quiz-id": _ctx.quizId }, null, 8, ["current-partner-quiz-id"]),
      _createVNode(_component_edit_question_quiz_modal, { "current-partner-quiz-id": _ctx.quizId }, null, 8, ["current-partner-quiz-id"])
    ]),
    _: 1
  }))
}