<template>
  <div>
    <question-option-input
      :read-only="true"
      placeholder="Answer text"
    />
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import QuestionOptionInput from './QuestionOptionInput.vue';

export default defineComponent({
  name: 'OptionText',
  components: {
    QuestionOptionInput,
  },
});
</script>
