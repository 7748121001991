
import {computed, defineComponent, ref} from 'vue';
import {useStore} from 'vuex';
import {key} from '@/store';
import DropDown from '@/components/DropDown.vue';
import TheModal from '@/components/TheModal.vue';
import BaseButton from '@/components/BaseButton.vue';
import { apiRemoveQuestionNew } from '@/api/QuestionApi';
import {useRouter} from 'vue-router';
import {routeQuestionDeleteSelectPartner} from '@/core/constants';
import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue';
import useModal from '@/hooks/useModal';

export default defineComponent({
  name: 'RemoveQuestionFromQuizModal',
  components: {
    DropDown,
    TheModal,
    BaseButton,
    ConfirmDeleteModal,
  },
  props: {
    currentPartnerQuizId: {
      type: Number,
      required: true,
    }
  },
  setup(props) {
    const store = useStore(key);
    const isOpen = computed(() => store.state.deleteQuestion.isDeleteQuestionOpen);
    const storeDeleteQuestionData = computed(() => store.state.deleteQuestion);
    const router = useRouter();

    const confirmDeleteModal = useModal(false);

    const questionCreateConfirmIsOpen = ref(false);
    interface OptionI {
      placeholderName: string;
      name: string;
      id: number;
    }
    const CREATE_QUESTION_CURRENT_PARTNER_ID = 1;
    const CREATE_QUESTION_ALL_PARTNER_ID = 2;
    const CREATE_QUESTION_SELECT_PARTNER_ID = 3;
    const currentOption = ref<OptionI>({
      placeholderName: 'to this one Partner',
      name: 'Delete Question to this Partner only',
      id: 1
    });
    const options = ref<Array<OptionI>>([
      {
        placeholderName: 'to this one Partner',
        name: 'Delete Question to this Partner only',
        id: CREATE_QUESTION_CURRENT_PARTNER_ID
      },
      {
        placeholderName: 'to all Partners',
        name: 'Delete Question for all Partners',
        id: CREATE_QUESTION_ALL_PARTNER_ID
      },
      {
        placeholderName: 'to selected Partners',
        name: 'Delete Question to selected Partners',
        id: CREATE_QUESTION_SELECT_PARTNER_ID
      },
    ]);

    const onDelete = () => {
      const optionId = currentOption.value.id;

      let partnerIds: Array<number> = [];
      if(optionId === CREATE_QUESTION_CURRENT_PARTNER_ID)  {
        partnerIds = [props.currentPartnerQuizId];
      }
      if(!storeDeleteQuestionData.value.question) throw new Error("Question doesn't exist");

      apiRemoveQuestionNew(
        props.currentPartnerQuizId,
        storeDeleteQuestionData.value.question.id,
        partnerIds
      )
        .then(() => {
          confirmDeleteModal.close();
          closeModal();
          questionCreateConfirmIsOpen.value = true;
          // store.commit('setQuestionEditMode', false);
        })
    }
    const onCancel = () => {
      closeModal();
    }

    const closeModal = () => {
      store.commit('setInitiateDeleteQuestion', {
        isDeleteQuestionOpen: false,
        question: null,
        questionPositionIndex: null,
        isSaveNowQuestionLocationOpen: false,
        quizIds: [],
      });
    }

    const setOption = (opt: OptionI) => {
      currentOption.value = opt;
    }

    const handleOpenConfirmDeleteModal = () => {
      const optionId = currentOption.value.id;

      if(optionId === CREATE_QUESTION_SELECT_PARTNER_ID) {
        router.push({
          name: routeQuestionDeleteSelectPartner,
          params: {
            id: props.currentPartnerQuizId,
          },
        });
      } else {
        confirmDeleteModal.open();
      }
    }
    return {
      isOpen,
      onDelete,
      onCancel,
      options,
      setOption,
      currentOption,
      questionCreateConfirmIsOpen,
      confirmDeleteModal,
      handleOpenConfirmDeleteModal,
    }
  }
});
