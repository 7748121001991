import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["placeholder", "disabled", "value", "rows"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("textarea", {
      class: "question-option-input",
      placeholder: _ctx.placeholder,
      disabled: _ctx.readOnly,
      value: _ctx.value,
      rows: _ctx.textareaInitialRows,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleInputChange($event.target.value)))
    }, null, 40, _hoisted_1)
  ]))
}